import crudProvider from '@fusionworks/ra-data-nest-crud';
import { fetchUtils, HttpError } from 'ra-core';

// const createDecorator = (decorators: ResourceDecorator[]) => (item: any) => {
//   if (!item) return item;

//   for (const func of decorators) {
//     item = func(item);
//   }

//   return item;
// };

const defaultHttpClient = (url: string, options: any = {}) => {
  if (!options.headers) options.headers = new Headers({ Accept: 'application/json' });
  const token = localStorage.getItem('token');
  options.headers.set('Authorization', `Bearer ${token}`);
  return fetchUtils.fetchJson(url, options);
};

export const createDataProvider = ({ apiBaseUrl }: { apiBaseUrl: string }) => {
  const defaultDataProvider = crudProvider(apiBaseUrl, defaultHttpClient);

  return async (type: string, resource: string, params: any) => {
    try {
      if (type === 'requestUploadUrl') {
        const response = await defaultHttpClient(`${apiBaseUrl}/request-upload-url?extension=${params.extension}`, {
          method: 'GET',
        });
        return { data: response.json };
      }

      if (type === 'resolve') {
        const response = await defaultHttpClient(`${apiBaseUrl}/${resource}/resolve`, {
          method: 'POST',
          body: JSON.stringify({ id: params.id, version: params.version }),
        });
        return { data: response.json };
      }

      if (type === 'runImporter') {
        const response = await defaultHttpClient(`${apiBaseUrl}/${resource}`, {
          method: 'POST',
          body: JSON.stringify({ importer: params.importer, useStored: params.useStored }),
        });
        return { data: response.json };
      }

      const result = await defaultDataProvider(type, resource, params);

      // if (type.startsWith('GET_')) {
      //   const resourceDefinition = resources.find((r) => r.name === resource);

      //   if (resourceDefinition && resourceDefinition.decorators) {
      //     const decorator = createDecorator(resourceDefinition.decorators);

      //     if (Array.isArray(result.data)) return { ...result, data: result.data.map(decorator) };
      //     return { ...result, data: decorator(result.data) };
      //   }
      // }

      return result;
    } catch (error) {
      if (error?.body?.message && Array.isArray(error.body.message)) {
        throw new HttpError(error.body.message.join('\n'), error.status, error.body);
      }

      throw error;
    }
  };
};
