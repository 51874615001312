import React from 'react';
import { EditButton, Show, SimpleShowLayout, Tab, TabbedShowLayout, TopToolbar } from 'react-admin';
import { ResourceDefinition } from '../utils/types';
import { renderField } from './field';
import { Title } from './util';

const ShowActions = ({ def, ...rest }: { def: ResourceDefinition; basePath?: any; data?: any }) => (
  <TopToolbar {...rest}>{def.updatable && <EditButton basePath={rest.basePath} record={rest.data} />}</TopToolbar>
);

export const createShow = (def: ResourceDefinition) => (props: any) => {
  const tabs = Array.from(new Set(def.fields.map((f) => f.tab).filter(Boolean))) as string[];
  const tabLess = def.fields.filter((f) => !f.tab);

  return (
    <Show {...props} actions={<ShowActions def={def} />} title={<Title def={def} />}>
      {tabs.length ? (
        <TabbedShowLayout>
          {tabLess.length > 0 && (
            <Tab label={def.generalTabLabel || 'general'}>{tabLess.map((field) => renderField(field))}</Tab>
          )}

          {tabs.map((tab) => (
            <Tab key={tab} label={tab} path={tab}>
              {def.fields.filter((f) => f.tab === tab).map((field) => renderField(field))}
            </Tab>
          ))}
        </TabbedShowLayout>
      ) : (
        <SimpleShowLayout>{def.fields.map((field) => renderField(field))}</SimpleShowLayout>
      )}
    </Show>
  );
};
