import List from '@material-ui/core/List';
import DefaultIcon from '@material-ui/icons/ViewList';
import { getResources } from 'ra-core';
import { MenuProps } from 'ra-ui-materialui/src/layout/Menu';
import React, { FC } from 'react';
import { MenuItemLink } from 'react-admin';
import { shallowEqual, useSelector } from 'react-redux';
import { RootState } from '../utils/types';
import MenuItem from './MenuItem';

const MyMenu: FC<MenuProps> = () => {
  const resources = useSelector(getResources, shallowEqual);
  const sidebarOpen = useSelector((state: RootState) => state.admin.ui.sidebarOpen);
  const location = useSelector((state: RootState) => state.router.location);

  const subItems = resources.filter((r) => !!r.options.parent);
  const mainItems = resources
    .filter((r) => !r.options.parent)
    .map((mainItem) => {
      return { ...mainItem, children: subItems.filter((subItem) => subItem.options.parent === mainItem.name) };
    });

  return (
    <List
      component="nav"
      disablePadding
      subheader={
        <div style={{ padding: '8px 0' }}>
          <MenuItemLink
            to={`/`}
            primaryText="Dashboard"
            leftIcon={<DefaultIcon />}
            dense={false}
            sidebarIsOpen={sidebarOpen}
          />
        </div>
      }
    >
      {mainItems
        .filter((resource) => resource.hasList)
        .map((resource: any) => (
          <MenuItem key={resource.name} resource={resource} sidebarOpen={sidebarOpen} pathname={location.pathname} />
        ))}
    </List>
  );
};

export default MyMenu;
