import { sanitizeListRestProps, useListContext } from 'ra-core';
import React, { cloneElement } from 'react';
import { BulkDeleteButton, CreateButton, Datagrid, EditButton, Filter, List, TopToolbar } from 'react-admin';
import { ResourceDefinition } from '../utils/types';
import { renderField } from './field';
import { renderInput } from './input';

const ListTitle = ({ def }: { def: ResourceDefinition }) => <>{def.labelPlural || def.label || def.name}</>;

const BulkActions = ({ def, ...rest }: { def: ResourceDefinition }) => (
  <>{def.deletable && <BulkDeleteButton {...rest} undoable={false} />}</>
);

const ListActions = (props: any) => {
  const { className, exporter, filters, maxResults, def, ...rest } = props;
  const { resource, displayedFilters, filterValues, basePath, showFilter } = useListContext();
  return (
    <TopToolbar className={className} {...sanitizeListRestProps(rest)}>
      {filters && cloneElement(filters, { resource, showFilter, displayedFilters, filterValues, context: 'button' })}
      {def.creatable && <CreateButton basePath={basePath} />}
    </TopToolbar>
  );
};

const ListFilters = ({ def, ...rest }: { def: ResourceDefinition }) => (
  <Filter {...rest}>{def.filters!.map((f) => renderInput(f, true))}</Filter>
);

export const createList = (def: ResourceDefinition) => (props: any) => (
  <List
    {...props}
    perPage={25}
    actions={<ListActions def={def} />}
    bulkActionButtons={<BulkActions def={def} />}
    title={<ListTitle def={def} />}
    filters={def.filters && def.filters.length > 0 ? <ListFilters def={def} /> : <></>}
  >
    <Datagrid size="medium" rowClick="show">
      {def.fields.filter((f) => !f.hideInList).map((field) => renderField(field, true))}
      {def.updatable && <EditButton />}
    </Datagrid>
  </List>
);
