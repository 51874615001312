import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import React from 'react';
import { Title } from 'react-admin';

const Dashboard = () => (
  <Card>
    <Title title="Willkommen zum Backend" />
    <CardContent>
      <h4 style={{ marginTop: 0 }}>Willkommen zum Backend</h4>
      <p>Von hier aus können alle Inhalte der GUGG-App verwaltet werden.</p>
    </CardContent>
  </Card>
);

export default Dashboard;
