import { makeStyles } from '@material-ui/core/styles';
import Alert from '@material-ui/lab/Alert';
import { useDataProvider, useInput, useTranslate } from 'ra-core';
import FileInputPreview from 'ra-ui-materialui/lib/input/FileInputPreview';
import React, { useCallback, useEffect, useState } from 'react';
import { Labeled } from 'react-admin';
import { FileRejection, useDropzone } from 'react-dropzone';
import { createImageLabel, ImageInputProps, resolveImageUrl, validateImageDimensions } from '../../utils/image';

const useStyles = makeStyles((theme) => ({
  root: { width: '100%' },
  dropZone: {
    background: theme.palette.background.default,
    cursor: 'pointer',
    padding: theme.spacing(1),
    textAlign: 'center',
    color: theme.palette.getContrastText(theme.palette.background.default),
  },
  preview: {
    display: 'inline-block',
  },
  removeButton: {
    display: 'inline-block',
    position: 'relative',
    float: 'left',
    '& button': {
      position: 'absolute',
      top: theme.spacing(1),
      right: theme.spacing(1),
      minWidth: theme.spacing(2),
      opacity: 0,
    },
    '&:hover button': {
      opacity: 1,
    },
  },
  image: {
    margin: '0.5rem',
    maxHeight: '10rem',
  },
}));

const ImageInput = (props: ImageInputProps) => {
  const { accept, maxSize, minSize, width, height, resource, source } = props;
  const {
    input: { onChange, value, ...inputProps },
  } = useInput(props);

  const [errors, setErrors] = useState<string[]>([]);
  const dataProvider = useDataProvider();
  const translate = useTranslate();
  const classes = useStyles(props);

  useEffect(() => {
    if (value && width && height) {
      validateImageDimensions({ url: value }, width, height).catch((error) => {
        setErrors([error.message]);
        onChange(undefined);
      });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [width, height, value]);

  const onDrop = useCallback(async (acceptedFiles: File[], rejectedFiles: FileRejection[]) => {
    setErrors([]);

    if (acceptedFiles.length) {
      try {
        const file = acceptedFiles[0];

        await validateImageDimensions({ file }, width, height);

        const response = await dataProvider.requestUploadUrl(resource, { extension: '.jpg' });
        await fetch(response.data.uploadUrl, {
          method: 'PUT',
          headers: { 'content-length': file.size.toString() },
          body: file,
        });
        onChange(response.data.filePath);
      } catch (error) {
        console.error(error);
        if (error?.message) {
          setErrors([error.message]);
        }
      }
    } else if (rejectedFiles) {
      setErrors(rejectedFiles[0].errors.map((error) => error.message));
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const { getRootProps, getInputProps } = useDropzone({ onDrop, accept, minSize, maxSize, multiple: false });

  return (
    <Labeled label={createImageLabel(props)} className={classes.root} source={source} resource={resource}>
      <>
        {errors.map((error) => (
          <Alert key={error} severity="error" style={{ width: 'auto', marginTop: 16, marginBottom: 16 }}>
            {error}
          </Alert>
        ))}

        <div className={classes.dropZone} {...getRootProps()}>
          <input {...getInputProps({ ...inputProps })} />
          <p>{translate('ra.input.file.upload_single')}</p>
        </div>

        {value && (
          <div className="previews">
            <FileInputPreview file={{}} onRemove={() => onChange(undefined)} className={classes.removeButton}>
              <div className={classes.preview}>
                <img src={resolveImageUrl(value)} alt="" className={classes.image} />
              </div>
            </FileInputPreview>
          </div>
        )}
      </>
    </Labeled>
  );
};

export default ImageInput;
