import { makeStyles } from '@material-ui/core/styles';
import L from 'leaflet';
import icon from 'leaflet/dist/images/marker-icon.png';
import iconShadow from 'leaflet/dist/images/marker-shadow.png';
import 'leaflet/dist/leaflet.css';
import get from 'lodash.get';
import React from 'react';
import { FieldProps, Labeled } from 'react-admin';
import { Map, Marker, TileLayer } from 'react-leaflet';

let DefaultIcon = L.icon({
  iconUrl: icon,
  shadowUrl: iconShadow,
  iconSize: [25, 41],
  iconAnchor: [12, 41],
  popupAnchor: [1, -34],
  tooltipAnchor: [16, -28],
  shadowSize: [41, 41],
});

L.Marker.prototype.options.icon = DefaultIcon;

const useStyles = makeStyles((theme) => ({
  root: {
    height: '32rem',
    overflow: 'hidden',
    zIndex: 10,
  },
}));

interface Props extends FieldProps {
  latitudeSource: string;
  longitudeSource: string;
  zoom: number;
}

const MapField = (props: Props) => {
  const { record, latitudeSource, longitudeSource, zoom } = props;
  const classes = useStyles(props);

  const latitude = get(record, latitudeSource);
  const longitude = get(record, longitudeSource);

  return (
    <Labeled {...props} fullWidth>
      <div>
        <Map center={[latitude, longitude]} zoom={zoom} className={classes.root}>
          <TileLayer
            attribution='&copy; <a href="https://osm.org/copyright">OpenStreetMap</a> contributors'
            url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
          />

          <Marker position={[latitude, longitude]} />
        </Map>
      </div>
    </Labeled>
  );
};

export default MapField;
