import jwtDecode from 'jwt-decode';
import fetch from 'node-fetch';

export default ({ apiBaseUrl }: { apiBaseUrl: string }) => ({
  login: async ({ email, password }: { email: string; password: string }) => {
    try {
      const response = await fetch(`${apiBaseUrl}/login`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ email, password }),
      });

      if (!response.ok) throw new Error();

      const json = await response.json();

      localStorage.setItem('token', json.token);
    } catch (error) {
      localStorage.removeItem('token');
      throw new Error('Login fehlgeschlagen. Versuchen Sie es erneut.');
    }
  },
  logout: () => {
    localStorage.removeItem('token');
    return Promise.resolve();
  },
  checkError: async (error: any) => {
    if (error.status === 401) throw new Error();
  },
  checkAuth: () => {
    const token = localStorage.getItem('token');
    if (!token) return Promise.reject('Sie sind kein Admin.');

    const decoded: any = jwtDecode(token);

    if (decoded.exp && decoded.exp * 1000 < new Date().getTime()) return Promise.reject('Sitzung abgelaufen.');

    return decoded ? Promise.resolve() : Promise.reject();
  },
  getPermissions: () => {
    return Promise.resolve(true);
  },
});
