import { makeStyles } from '@material-ui/core/styles';
import L from 'leaflet';
import icon from 'leaflet/dist/images/marker-icon.png';
import iconShadow from 'leaflet/dist/images/marker-shadow.png';
import 'leaflet/dist/leaflet.css';
import { InputProps, useInput } from 'ra-core';
import React, { useEffect, useRef, useState } from 'react';
import { Labeled } from 'react-admin';
import { Map, Marker, TileLayer } from 'react-leaflet';
import { useLocation } from 'react-router-dom';

let DefaultIcon = L.icon({
  iconUrl: icon,
  shadowUrl: iconShadow,
  iconSize: [25, 41],
  iconAnchor: [12, 41],
  popupAnchor: [1, -34],
  tooltipAnchor: [16, -28],
  shadowSize: [41, 41],
});

L.Marker.prototype.options.icon = DefaultIcon;

const useStyles = makeStyles((theme) => ({
  root: {
    height: '32rem',
    overflow: 'hidden',
    zIndex: 10,
  },
}));

interface Props extends InputProps {
  latitudeSource: string;
  longitudeSource: string;
  zoom: number;
}

const MapInput = (props: Props) => {
  const { latitudeSource, longitudeSource, zoom } = props;
  const location = useLocation();
  const mapRef = useRef<Map>();
  const [center, setCenter] = useState<L.LatLngTuple>();

  const {
    input: { onChange: latitudeChange, value: latitude },
  } = useInput({ ...props, source: latitudeSource });
  const {
    input: { onChange: longitudeChange, value: longitude },
  } = useInput({ ...props, source: longitudeSource });

  useEffect(() => {
    setCenter([latitude, longitude]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!mapRef.current) return;

    const map = mapRef.current.leafletElement;
    map.whenReady(() => map.invalidateSize());
  }, [location]);

  useEffect(() => {
    if (!mapRef.current) return;

    const map = mapRef.current.leafletElement;
    map.whenReady(() => map.panInside([latitude, longitude], { padding: [50, 50] }));
  }, [latitude, longitude]);

  const classes = useStyles();

  return (
    <Labeled {...props} fullWidth>
      <div>
        <Map center={center} zoom={zoom} className={classes.root} ref={mapRef as any}>
          <TileLayer
            attribution='&copy; <a href="https://osm.org/copyright">OpenStreetMap</a> contributors'
            url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
          />

          <Marker
            position={[latitude, longitude]}
            draggable
            autoPan
            onDragend={(event: any) => {
              const { lat, lng } = event.target.getLatLng();
              latitudeChange(lat);
              longitudeChange(lng);
            }}
          />
        </Map>
      </div>
    </Labeled>
  );
};

export default MapInput;
