import { makeStyles } from '@material-ui/core/styles';
import Alert from '@material-ui/lab/Alert';
import { useDataProvider, useInput, useTranslate } from 'ra-core';
import FileInputPreview from 'ra-ui-materialui/lib/input/FileInputPreview';
import React, { useEffect, useState } from 'react';
import { Labeled } from 'react-admin';
import { FileRejection, useDropzone } from 'react-dropzone';
import { SortableContainer, SortableElement, SortEnd } from 'react-sortable-hoc';
import { createImageLabel, ImageInputProps, resolveImageUrl, validateImageDimensions } from '../../utils/image';

const useStyles = makeStyles((theme) => ({
  root: { width: '100%' },
  dropZone: {
    background: theme.palette.background.default,
    cursor: 'pointer',
    padding: theme.spacing(1),
    textAlign: 'center',
    color: theme.palette.getContrastText(theme.palette.background.default),
  },
  previews: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  removeButton: {
    display: 'inline-block',
    position: 'relative',
    float: 'left',
    '& button': {
      position: 'absolute',
      top: theme.spacing(1),
      right: theme.spacing(1),
      minWidth: theme.spacing(2),
      opacity: 0,
    },
    '&:hover button': {
      opacity: 1,
    },
  },
  image: {
    margin: '0.5rem',
    maxHeight: '10rem',
    cursor: 'pointer',
  },
}));

const reorder = (list: any[], startIndex: number, endIndex: number): any[] => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);

  return result;
};

const SortableItem = SortableElement(
  ({ image, handleRemove, classes }: { image: string; handleRemove: (image: string) => void; classes: any }) => (
    <div>
      <FileInputPreview key={image} file={{}} onRemove={() => handleRemove(image)} className={classes.removeButton}>
        <div>
          <img src={resolveImageUrl(image)} alt="" className={classes.image} />
        </div>
      </FileInputPreview>
    </div>
  ),
);

const SortableList = SortableContainer(
  ({ images, handleRemove, classes }: { images: string[]; handleRemove: (image: string) => void; classes: any }) => {
    return (
      <div className={classes.previews}>
        {images.map((image, index) => (
          <SortableItem key={image} index={index} image={image} handleRemove={handleRemove} classes={classes} />
        ))}
      </div>
    );
  },
);

const ImageListInput = (props: ImageInputProps) => {
  const { accept, maxSize, minSize, width, height, resource, source } = props;
  const {
    input: { onChange, value, ...inputProps },
  } = useInput(props);
  const [errors, setErrors] = useState<string[]>([]);
  const [allValues, setAllValues] = useState<string[]>(value || []);
  const dataProvider = useDataProvider();
  const translate = useTranslate();
  const classes = useStyles(props);

  useEffect(() => {
    if (value !== allValues) {
      onChange(allValues);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [allValues]);

  useEffect(() => {
    if (allValues && width && height) {
      allValues.forEach((image) => {
        validateImageDimensions({ url: image }, width, height).catch((error) => {
          setErrors([error.message]);
          setAllValues((old) => old.filter((i) => i !== image));
        });
      });
    }
  }, [width, height, allValues]);

  const onDrop = async (acceptedFiles: File[], rejectedFiles: FileRejection[]) => {
    setErrors([]);

    if (acceptedFiles.length) {
      await Promise.all(
        acceptedFiles.map(async (file) => {
          try {
            await validateImageDimensions({ file }, width, height);

            const response = await dataProvider.requestUploadUrl(resource, { extension: '.jpg' });
            await fetch(response.data.uploadUrl, {
              method: 'PUT',
              headers: { 'content-length': file.size.toString() },
              body: file,
            });
            setAllValues((old) => [...old, response.data.filePath]);
          } catch (error) {
            console.error(error);
            if (error?.message) {
              setErrors((old) => [...old, error.message]);
            }
          }
        }),
      );
    } else if (rejectedFiles) {
      setErrors(rejectedFiles.flatMap((file) => file.errors.map((error) => error.message)));
    }
  };

  const { getRootProps, getInputProps } = useDropzone({ onDrop, accept, minSize, maxSize, multiple: true });

  const handleRemove = (image: string) => {
    // TODO: does not work because sort is triggered first
    setAllValues((old) => old.filter((i) => i !== image));
  };

  const onSortEnd = (sort: SortEnd) => {
    document.body.classList.remove('dragging');
    setAllValues((old) => reorder(old, sort.oldIndex, sort.newIndex));
  };

  return (
    <Labeled label={createImageLabel(props)} className={classes.root} source={source} resource={resource}>
      <>
        {errors.map((error) => (
          <Alert key={error} severity="error" style={{ width: 'auto', marginTop: 16, marginBottom: 16 }}>
            {error}
          </Alert>
        ))}

        <div className={classes.dropZone} {...getRootProps()}>
          <input {...getInputProps({ ...inputProps })} />
          <p>{translate('ra.input.file.upload_single')}</p>
        </div>

        <SortableList
          classes={classes}
          images={allValues}
          onSortStart={() => document.body.classList.add('dragging')}
          onSortEnd={onSortEnd}
          handleRemove={handleRemove}
          axis="xy"
          helperClass="dragging-container"
          useWindowAsScrollContainer
        />
      </>
    </Labeled>
  );
};

export default ImageListInput;
