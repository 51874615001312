import * as MenuIcons from '@material-ui/icons';

export type MenuIcon = keyof typeof MenuIcons;

export interface ResourceDefinition {
  name: string;
  label?: string;
  labelPlural?: string;
  menuLabel?: string;
  icon?: MenuIcon;
  parent?: string;
  creatable: boolean;
  updatable: boolean;
  deletable: boolean;
  noShow?: boolean;
  noList?: boolean;
  generalTabLabel?: string;
  filters?: ResourceField[];
  fields: ResourceField[];
  decorators?: ResourceDecorator[];
}

export interface ResourceField {
  name: string;
  label?: string;
  type: FieldType;
  editType?: FieldType;
  defaultValue?: any;
  reference?: ResourceReference;
  map?: ResourceMap;
  image?: ResourceImage;
  choices?: { id: string; name: string }[];
  dependsOn?: (record: any) => boolean;
  longText?: boolean;
  tab?: string;
  hideInList?: boolean;
  notEditable?: boolean;
  trimInList?: number;
  showSeconds?: boolean;
}

export interface ResourceReference {
  resource: string;
  relationId: string;
  fieldName?: string;
  fields?: ResourceField[];
}

export interface ResourceMap {
  latitudeSource: string;
  longitudeSource: string;
  zoom: number;
}

export interface ResourceImage {
  accept?: string | string[];
  minSize?: number;
  maxSize?: number;
  height?: number;
  width?: number;
}

export type ResourceDecorator = (item: Record<string, any>) => Record<string, any>;

export interface RootState {
  admin: {
    loading: number;
    notifications: any[];
    resources: any;
    ui: {
      automaticRefreshEnabled: boolean;
      optimistic: boolean;
      sidebarOpen: boolean;
      viewVersion: number;
    };
  };
  router: {
    action: string;
    location: {
      hash: string;
      pathname: string;
      query: any;
      search: string;
      state: any;
    };
  };
}

export enum FieldType {
  Boolean = 'boolean',
  Date = 'date',
  DateTime = 'date-time',
  Email = 'email',
  Image = 'image',
  ImageList = 'image-list',
  Map = 'map',
  ManyReference = 'many-reference',
  ManyReferenceList = 'many-reference-list',
  MultiSelect = 'MultiSelect',
  Number = 'number',
  OpeningHours = 'opening-hours',
  RadioGroup = 'radio-group',
  Reference = 'reference',
  String = 'string',
  URL = 'url',
}
