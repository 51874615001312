import React from 'react';
import { Create, FormTab, SimpleForm, TabbedForm } from 'react-admin';
import { ResourceDefinition } from '../utils/types';
import { renderInput } from './input';

const CreateTitle = ({ def }: { def: ResourceDefinition }) => <>{def.label || def.name} erstellen</>;

export const createCreate = (def: ResourceDefinition) => (props: any) => {
  const fields = def.fields.filter((f) => !f.notEditable);
  const tabs = Array.from(new Set(fields.map((f) => f.tab).filter(Boolean))) as string[];
  const tabLess = fields.filter((f) => !f.tab);

  return (
    <Create {...props} title={<CreateTitle def={def} />} undoable={false}>
      {tabs.length ? (
        <TabbedForm redirect="show">
          {tabLess.length > 0 && (
            <FormTab label={def.generalTabLabel || 'general'}>{tabLess.map((f) => renderInput(f))}</FormTab>
          )}

          {tabs.map((tab) => (
            <FormTab key={tab} label={tab} path={tab}>
              {fields.filter((f) => f.tab === tab).map((f) => renderInput(f))}
            </FormTab>
          ))}
        </TabbedForm>
      ) : (
        <SimpleForm redirect="show">{fields.map((f) => renderInput(f))}</SimpleForm>
      )}
    </Create>
  );
};
