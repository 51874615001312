import DateFnsUtils from '@date-io/date-fns';
import { KeyboardTimePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import React, { useEffect, useState } from 'react';

const TimeInput = ({ onChange, value }: { onChange: (value: number) => void; value: number }) => {
  const [selectedDate, setSelectedDate] = useState<Date | null>();

  useEffect(() => {
    if (value === null || value === undefined || Number.isNaN(value)) return setSelectedDate(null);

    const hours = Math.floor(value / 60);
    const minutes = value % 60;
    const date = new Date();

    date.setHours(hours, minutes);
    setSelectedDate(date);
  }, [value]);

  const handleChange = (value: Date | null) => {
    if (!value) return onChange(NaN);
    if (Number.isNaN(value.getTime())) return;

    const hours = value.getHours();
    const minutes = value.getMinutes();
    onChange(hours * 60 + minutes);
  };

  return (
    <MuiPickersUtilsProvider utils={DateFnsUtils}>
      <KeyboardTimePicker
        placeholder="08:00"
        mask="__:__"
        ampm={false}
        value={selectedDate}
        onChange={(date) => handleChange(date)}
      />
    </MuiPickersUtilsProvider>
  );
};

export default TimeInput;
