import { makeStyles, Typography } from '@material-ui/core';
import { FirstPage, LastPage, OfflineBolt, VerticalAlignBottom } from '@material-ui/icons';
import React from 'react';
import {
  ArrayField,
  Button,
  Datagrid,
  List,
  ListProps,
  ReferenceField,
  ShowActionsProps,
  ShowContextProvider,
  ShowProps,
  ShowView,
  SimpleShowLayout,
  SingleFieldList,
  TextField,
  TopToolbar,
  useDataProvider,
  useNotify,
  useRedirect,
  useShowController,
} from 'react-admin';
import ChipField from './fields/ChipField';

export const ConflictIcon = OfflineBolt;

export const ConflictList = (props: ListProps) => (
  <List {...props} perPage={25}>
    <Datagrid size="medium" rowClick="show">
      <TextField source="entityTable" />
      <TextField source="entityId" />

      <ArrayField source="path">
        <SingleFieldList>
          <ChipField />
        </SingleFieldList>
      </ArrayField>
    </Datagrid>
  </List>
);

const useStyles = makeStyles({
  container: {
    alignItems: 'center',
  },
  button: {
    marginLeft: 16,
  },
});

const Actions = (props: ShowActionsProps) => {
  const dataProvider = useDataProvider();
  const redirect = useRedirect();
  const notify = useNotify();
  const classes = useStyles();

  const handleClick = async (version: 'original' | 'meanwhile' | 'new') => {
    try {
      await dataProvider.resolve(props.resource, { id: props.data!.id, version });
      redirect('/conflicts');
    } catch (error) {
      console.error(error);
      notify(`Unable to resolve conflict: ${error.message}`, 'error');
    }
  };

  return (
    <TopToolbar {...props} className={classes.container}>
      <Typography variant="body1">Resolve:</Typography>

      <Button label="Original" variant="contained" className={classes.button} onClick={() => handleClick('original')}>
        <FirstPage />
      </Button>
      <Button label="Meanwhile" variant="contained" className={classes.button} onClick={() => handleClick('meanwhile')}>
        <VerticalAlignBottom />
      </Button>
      <Button label="New" variant="contained" className={classes.button} onClick={() => handleClick('new')}>
        <LastPage />
      </Button>
    </TopToolbar>
  );
};

export const ConflictShow = (props: ShowProps) => {
  const controllerProps = useShowController(props);

  return (
    <ShowContextProvider value={controllerProps}>
      <ShowView {...props} {...controllerProps} actions={<Actions />}>
        <SimpleShowLayout>
          <TextField source="id" />
          <TextField source="entityTable" />

          {controllerProps.record && (
            <ReferenceField source="entityId" label="Entity" reference={controllerProps.record.entityTable} link="show">
              <TextField source="name" />
            </ReferenceField>
          )}

          <ArrayField source="path">
            <SingleFieldList>
              <ChipField />
            </SingleFieldList>
          </ArrayField>

          <TextField source="originalValue" />
          <TextField source="meanwhileValue" />
          <TextField source="newValue" />
        </SimpleShowLayout>
      </ShowView>
    </ShowContextProvider>
  );
};
