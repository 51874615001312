import { InputProps } from 'ra-core';
import { ResourceImage } from './types';

export const validateImageDimensions = (
  { file, url }: { file?: File; url?: string },
  width?: number,
  height?: number,
) =>
  new Promise<void>((resolve, reject) => {
    if (!file && !url) return reject('file or url required');
    if (!width && !height) return resolve();

    const img = new Image();
    const _URL = window.URL || window.webkitURL;
    img.src = file ? _URL.createObjectURL(file) : url!;
    img.onload = () => {
      if (width && img.width !== width) reject(createDimensionError('width', width));
      if (height && img.height !== height) reject(createDimensionError('height', height));
      resolve();
    };
  });

export const createDimensionError = (dimension: string, desiredValue: number) =>
  new Error(`The image ${dimension} is not ${desiredValue} Pixel!`);

export type ImageInputProps = InputProps & ResourceImage;

const formatBytes = (bytes: number) => {
  if (bytes < 2_000) return bytes.toLocaleString('de-DE', { style: 'decimal' }) + ' B';
  if (bytes < 2_000_000) return (bytes / 1000).toLocaleString('de-DE', { style: 'decimal' }) + ' KB';
  if (bytes < 2_000_000_000) return (bytes / 1_000_000).toLocaleString('de-DE', { style: 'decimal' }) + ' MB';
  return (bytes / 1_000_000_000).toLocaleString('de-DE', { style: 'decimal' }) + ' GB';
};

export const createImageLabel = ({ label, width, height, minSize, maxSize, accept }: ImageInputProps) => {
  const constraintParts: string[] = [];

  if (width && height) constraintParts.push(`${width}x${height}`);
  if (minSize) constraintParts.push(`min. ${formatBytes(minSize)}`);
  if (maxSize) constraintParts.push(`max. ${formatBytes(maxSize)}`);
  if (accept) constraintParts.push(...(Array.isArray(accept) ? accept : [accept]));

  return label + (constraintParts.length > 0 ? ` (${constraintParts.join(', ')})` : '');
};

export const resolveImageUrl = (url: string) =>
  /^https?:\/\//.test(url) ? url : `${window.config.CITYHUB_ADMIN_CDN_BASE_URL}/${url}`;
