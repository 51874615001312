import React from 'react';
import {
  ArrayField,
  BooleanField,
  Datagrid,
  DateField,
  EmailField,
  FunctionField,
  Labeled,
  NumberField,
  ReferenceArrayField,
  ReferenceField,
  SelectField,
  SingleFieldList,
  TextField,
  UrlField,
} from 'react-admin';
import ChipField from '../components/fields/ChipField';
import ImageField from '../components/fields/ImageField';
import ImageListField from '../components/fields/ImageListField';
import MapField from '../components/fields/MapField';
import OpeningHoursField from '../components/fields/OpeningHoursField';
import { FieldType, ResourceField } from '../utils/types';

const findFieldType = (field: ResourceField, isList: boolean, props: any) => {
  switch (field.type) {
    case FieldType.Boolean:
      return <BooleanField {...props} key={field.name} source={field.name} label={field.label} />;
    case FieldType.Date:
      return (
        <DateField
          {...props}
          key={field.name}
          source={field.name}
          label={field.label}
          locales="de-DE"
          options={{ year: 'numeric', month: '2-digit', day: '2-digit' }}
        />
      );
    case FieldType.DateTime:
      return (
        <DateField
          {...props}
          key={field.name}
          source={field.name}
          label={field.label}
          locales="de-DE"
          options={{
            year: 'numeric',
            month: '2-digit',
            day: '2-digit',
            hour: '2-digit',
            minute: '2-digit',
            second: field.showSeconds ? '2-digit' : undefined,
          }}
          showTime
        />
      );
    case FieldType.Email:
      return <EmailField {...props} key={field.name} source={field.name} label={field.label} />;
    case FieldType.Image:
      return <ImageField {...props} key={field.name} source={field.name} title={field.label} label={field.label} />;
    case FieldType.ImageList:
      return <ImageListField {...props} key={field.name} source={field.name} label={field.label} />;
    case FieldType.Map:
      if (!field.map) throw new Error(`Missing "map" property for field type "${field.type}"`);
      return (
        <MapField
          {...props}
          key={field.name}
          source={field.name}
          label={field.label}
          latitudeSource={field.map.latitudeSource}
          longitudeSource={field.map.longitudeSource}
          zoom={field.map.zoom}
        />
      );
    case FieldType.ManyReference:
      if (!field.reference) throw new Error(`Missing "reference" property for field type "${field.type}"`);
      return (
        <ReferenceArrayField
          {...props}
          key={field.name}
          source={field.reference.relationId}
          label={field.label}
          reference={field.reference.resource}
        >
          <SingleFieldList>
            <ChipField source={field.reference.fieldName || 'name'} />
          </SingleFieldList>
        </ReferenceArrayField>
      );
    case FieldType.ManyReferenceList:
      if (!field.reference) throw new Error(`Missing "reference" property for field type "${field.type}"`);
      if (!field.reference.fields)
        throw new Error(`Missing "reference.fields" property for field type "${field.type}"`);
      return (
        <ReferenceArrayField
          {...props}
          key={field.name}
          source={field.reference.relationId}
          label={field.label}
          reference={field.reference.resource}
        >
          <Datagrid>{field.reference.fields.map((field) => renderField(field, true))}</Datagrid>
        </ReferenceArrayField>
      );
    case FieldType.MultiSelect:
      if (!field.choices) throw new Error(`Missing "choices" property for field type "${field.type}"`);
      const replacements = Object.fromEntries(field.choices.map((choice) => [choice.id, choice.name]));
      return (
        <ArrayField {...props} key={field.name} source={field.name} label={field.label}>
          <SingleFieldList>
            <ChipField replacements={replacements} />
          </SingleFieldList>
        </ArrayField>
      );
    case FieldType.Number:
      return <NumberField {...props} key={field.name} source={field.name} label={field.label} />;
    case FieldType.OpeningHours:
      return <OpeningHoursField {...props} key={field.name} source={field.name} label={field.label} />;
    case FieldType.RadioGroup:
      if (!field.choices) throw new Error(`Missing "choices" property for field type "${field.type}"`);
      return (
        <SelectField {...props} key={field.name} source={field.name} label={field.label} choices={field.choices} />
      );
    case FieldType.Reference:
      if (!field.reference) throw new Error(`Missing "reference" property for field type "${field.type}"`);
      return (
        <ReferenceField
          {...props}
          key={field.name}
          source={field.reference.relationId}
          label={field.label}
          reference={field.reference.resource}
          link="show"
        >
          <TextField source={field.reference.fieldName || 'name'} />
        </ReferenceField>
      );
    case FieldType.String:
      if (isList && field.trimInList)
        return (
          <FunctionField
            {...props}
            key={field.name}
            source={field.name}
            label={field.label}
            render={(record: any) =>
              record[field.name] ? `${record[field.name].toString().substring(0, field.trimInList)}...` : ''
            }
          />
        );
      return <TextField {...props} key={field.name} source={field.name} label={field.label} />;
    case FieldType.URL:
      return <UrlField {...props} key={field.name} source={field.name} label={field.label} />;
    default:
      throw new Error(`Unknown field type "${field.type}"`);
  }
};

export const renderField = (field: ResourceField, isList?: boolean) => {
  if (field.dependsOn) {
    const Component = ({ record, ...rest }: any) =>
      record && field.dependsOn!(record) ? (
        <Labeled {...rest} label={field.label} source={field.name} record={record}>
          {findFieldType(field, !!isList, { ...rest, record })}
        </Labeled>
      ) : null;

    return <Component key={field.name} source={field.name} />;
  }

  return findFieldType(field, !!isList, {});
};
