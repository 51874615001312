import { makeStyles } from '@material-ui/core';
import { CheckCircle, CloudDownload, Error, Event, OfflineBolt, Store } from '@material-ui/icons';
import React from 'react';
import {
  Button,
  Datagrid,
  FunctionField,
  List,
  ListActionsProps,
  ListProps,
  Show,
  ShowProps,
  SimpleShowLayout,
  TopToolbar,
  useDataProvider,
  useNotify,
  useRefresh,
} from 'react-admin';
import { useImportFinishedSubscription } from '../api/types-and-hooks';
import { renderField } from '../resources/field';
import { FieldType, ResourceField } from '../utils/types';

export const ImportIcon = CloudDownload;

const fields: ResourceField[] = [
  { name: 'id', type: FieldType.String, hideInList: true },
  { name: 'importId', label: 'Import Id', type: FieldType.String, hideInList: true },
  { name: 'importer', type: FieldType.String },
  { name: 'start', type: FieldType.DateTime, showSeconds: true },
  { name: 'end', type: FieldType.DateTime, showSeconds: true },
  { name: 'error', type: FieldType.String, hideInList: true },
  { name: 'fromStorage', type: FieldType.Boolean, hideInList: true },
];

const useStyles = makeStyles({
  button: {
    marginLeft: 16,
  },
});

const ListActions = (props: ListActionsProps) => {
  const dataProvider = useDataProvider();
  const notify = useNotify();
  const classes = useStyles();

  const handleClick = async (importer: 'STORES_RAVENSBURG' | 'EVENTS_RAVENSBURG' | 'EVENTS_WEINGARTEN') => {
    try {
      if (!window.confirm(`Are sure you want to run the importer "${importer}"?`)) return;
      const useStored = window.confirm('Do want to use the already stored data for this import?');
      await dataProvider.runImporter(props.resource, { importer, useStored });
    } catch (error) {
      console.error(error);
      notify(`Unable to run importer: ${error.message}`, 'error');
    }
  };

  return (
    <TopToolbar {...props}>
      <Button
        label="Ravensburg"
        variant="contained"
        className={classes.button}
        onClick={() => handleClick('STORES_RAVENSBURG')}
      >
        <Store />
      </Button>
      <Button
        label="Ravensburg"
        variant="contained"
        className={classes.button}
        onClick={() => handleClick('EVENTS_RAVENSBURG')}
      >
        <Event />
      </Button>
      <Button
        label="Weingarten"
        variant="contained"
        className={classes.button}
        onClick={() => handleClick('EVENTS_WEINGARTEN')}
      >
        <Event />
      </Button>
    </TopToolbar>
  );
};

export const ImportList = (props: ListProps) => {
  const refresh = useRefresh();

  useImportFinishedSubscription({
    onSubscriptionData: (data) => {
      console.log(data);
      refresh();
    },
  });

  return (
    <List {...props} perPage={25} actions={<ListActions />} title="Import Messages">
      <Datagrid size="medium" rowClick="show">
        <FunctionField
          label="Type"
          render={(record: any) =>
            record.type === 'SUCCESS' ? (
              <CheckCircle color="primary" />
            ) : record.type === 'CONFLICT' ? (
              <OfflineBolt style={{ color: '#ffbb00' }} />
            ) : (
              <Error color="error" />
            )
          }
        />
        {fields.filter((f) => !f.hideInList).map((field) => renderField(field, true))}
      </Datagrid>
    </List>
  );
};

export const ImportShow = (props: ShowProps) => (
  <Show {...props}>
    <SimpleShowLayout>
      {fields.slice(0, 2).map((field) => renderField(field))}
      <FunctionField
        label="Type"
        style={{ display: 'flex', alignItems: 'center' }}
        render={(record: any) => (
          <>
            {record.type === 'SUCCESS' ? (
              <CheckCircle color="primary" />
            ) : record.type === 'CONFLICT' ? (
              <OfflineBolt style={{ color: '#ffbb00' }} />
            ) : (
              <Error color="error" />
            )}
            <span style={{ marginLeft: 8 }}>{record.type}</span>
          </>
        )}
      />
      {fields.slice(2).map((field) => renderField(field))}
    </SimpleShowLayout>
  </Show>
);
