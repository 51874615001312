import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type Maybe<T> = T | undefined;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  /** A date-time string at UTC, such as 2019-12-03T09:54:33Z, compliant with the date-time format. */
  DateTime: string;
};

export type Address = {
  __typename?: 'Address';
  city: Scalars['String'];
  line1: Scalars['String'];
  line2?: Maybe<Scalars['String']>;
  zipCode: Scalars['String'];
};

export type AllBikeStationsFilter = {
  favorites?: Maybe<Scalars['Boolean']>;
  location?: Maybe<LocationFilter>;
  locationWindow?: Maybe<LocationWindow>;
  search?: Maybe<Scalars['String']>;
};

export type AllBusStopsFilter = {
  favorites?: Maybe<Scalars['Boolean']>;
  location?: Maybe<LocationFilter>;
  locationWindow?: Maybe<LocationWindow>;
  search?: Maybe<Scalars['String']>;
};

export type AllEventsQueryFilter = {
  atFavoriteLocations?: Maybe<Scalars['Boolean']>;
  categories?: Maybe<Array<Scalars['ID']>>;
  currentlyHappening?: Maybe<Scalars['Boolean']>;
  eventLocation?: Maybe<Scalars['ID']>;
  hasReminders?: Maybe<Scalars['Boolean']>;
  search?: Maybe<Scalars['String']>;
  time?: Maybe<Timefilter>;
};

export type AllMovieScreeningsQueryFilter = {
  between?: Maybe<BetweenFilter>;
  theaterId?: Maybe<Scalars['ID']>;
  time?: Maybe<Timefilter>;
};

export type AppleLoginArgs = {
  code: Scalars['String'];
  name?: Maybe<Scalars['String']>;
};

export type BannerAd = {
  __typename?: 'BannerAd';
  buttonText?: Maybe<Scalars['String']>;
  createdAt: Scalars['DateTime'];
  id: Scalars['ID'];
  image?: Maybe<Scalars['String']>;
  link: Scalars['String'];
  name: Scalars['String'];
  store: Store;
  text?: Maybe<Scalars['String']>;
  type: BannerAdType;
  updatedAt: Scalars['DateTime'];
  validFrom: Scalars['DateTime'];
  validUntil: Scalars['DateTime'];
  viewId: Scalars['ID'];
};

export type BannerAdResult = {
  __typename?: 'BannerAdResult';
  results: Array<BannerAd>;
  total: Scalars['Int'];
};

export enum BannerAdType {
  Image = 'IMAGE',
  ImageText = 'IMAGE_TEXT',
  Text = 'TEXT',
}

export type BetweenFilter = {
  from: Scalars['DateTime'];
  to: Scalars['DateTime'];
};

export type BikeStation = {
  __typename?: 'BikeStation';
  city: Scalars['String'];
  id: Scalars['Int'];
  information: StationInformation;
  isFavorite: Scalars['Boolean'];
  latitude: Scalars['Float'];
  longitude: Scalars['Float'];
  name: Scalars['String'];
  placeType: Scalars['String'];
};

export type BikeStationFavorite = {
  __typename?: 'BikeStationFavorite';
  bikeStation: BikeStation;
  bikeStationId: Scalars['Int'];
  createdAt: Scalars['DateTime'];
  id: Scalars['ID'];
  updatedAt: Scalars['DateTime'];
};

export type BikeStationResult = {
  __typename?: 'BikeStationResult';
  results: Array<BikeStation>;
  total: Scalars['Int'];
};

export type BusStop = {
  __typename?: 'BusStop';
  city: Scalars['String'];
  departures: Array<Departure>;
  id: Scalars['Int'];
  isFavorite: Scalars['Boolean'];
  latitude: Scalars['Float'];
  longitude: Scalars['Float'];
  name: Scalars['String'];
  placeType: Scalars['String'];
};

export type BusStopFavorite = {
  __typename?: 'BusStopFavorite';
  busStop: BusStop;
  busStopId: Scalars['Int'];
  createdAt: Scalars['DateTime'];
  id: Scalars['ID'];
  updatedAt: Scalars['DateTime'];
};

export type BusStopResult = {
  __typename?: 'BusStopResult';
  results: Array<BusStop>;
  total: Scalars['Int'];
};

export type ContactInformation = {
  __typename?: 'ContactInformation';
  emailAddress?: Maybe<Scalars['String']>;
  phoneNumber?: Maybe<Scalars['String']>;
  website?: Maybe<Scalars['String']>;
};

export type DailyWeather = {
  __typename?: 'DailyWeather';
  day: Scalars['DateTime'];
  description: Scalars['String'];
  icon: WeatherIconName;
  temperature: Temperature;
};

export type Departure = {
  __typename?: 'Departure';
  destinationText: Scalars['String'];
  lineColor: Scalars['String'];
  lineId: Scalars['String'];
  lineName: Scalars['String'];
  plannedTime: Scalars['DateTime'];
  track?: Maybe<Scalars['String']>;
  tripId: Scalars['String'];
  visitNumber: Scalars['Float'];
};

export type EmailLoginArgs = {
  email: Scalars['String'];
  password: Scalars['String'];
};

export type Event = {
  __typename?: 'Event';
  begin: Scalars['DateTime'];
  categories: Array<EventCategory>;
  createdAt: Scalars['DateTime'];
  description: Scalars['String'];
  end: Scalars['DateTime'];
  eventReminders: Array<EventReminder>;
  hidden: Scalars['Boolean'];
  id: Scalars['ID'];
  image?: Maybe<Scalars['String']>;
  location: EventLocation;
  name: Scalars['String'];
  updatedAt: Scalars['DateTime'];
};

export type EventImageArgs = {
  size: EventImageSize;
};

export type EventCategory = {
  __typename?: 'EventCategory';
  createdAt: Scalars['DateTime'];
  events: Array<Event>;
  icon?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  name: Scalars['String'];
  updatedAt: Scalars['DateTime'];
};

export type EventCategoryEventsArgs = {
  filter?: Maybe<EventQueryFilter>;
};

export type EventCategoryResult = {
  __typename?: 'EventCategoryResult';
  results: Array<EventCategory>;
  total: Scalars['Int'];
};

export enum EventImageSize {
  HeaderImage = 'HEADER_IMAGE',
  ListThumb = 'LIST_THUMB',
}

export type EventLocation = {
  __typename?: 'EventLocation';
  address: OptionalAddress;
  categories: Array<EventLocationCategory>;
  contact: ContactInformation;
  createdAt: Scalars['DateTime'];
  currentOpeningHours?: Maybe<Openings>;
  description?: Maybe<Scalars['String']>;
  events: Array<Event>;
  id: Scalars['ID'];
  images: Array<Scalars['String']>;
  isFavorite: Scalars['Boolean'];
  location: OptionalLocation;
  logo?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  openingHours: Array<EventLocationOpeningHour>;
  updatedAt: Scalars['DateTime'];
};

export type EventLocationEventsArgs = {
  filter?: Maybe<EventQueryFilter>;
};

export type EventLocationCategory = {
  __typename?: 'EventLocationCategory';
  createdAt: Scalars['DateTime'];
  eventLocations: Array<Event>;
  icon?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  name: Scalars['String'];
  updatedAt: Scalars['DateTime'];
};

export type EventLocationCategoryResult = {
  __typename?: 'EventLocationCategoryResult';
  results: Array<EventLocationCategory>;
  total: Scalars['Int'];
};

export type EventLocationOpeningHour = {
  __typename?: 'EventLocationOpeningHour';
  createdAt: Scalars['DateTime'];
  id: Scalars['ID'];
  openings: Openings;
  updatedAt: Scalars['DateTime'];
  validFrom: Scalars['DateTime'];
  validUntil?: Maybe<Scalars['DateTime']>;
};

export type EventLocationResult = {
  __typename?: 'EventLocationResult';
  results: Array<EventLocation>;
  total: Scalars['Int'];
};

export type EventLocationsQueryFilter = {
  categories?: Maybe<Array<Scalars['ID']>>;
  favorites?: Maybe<Scalars['Boolean']>;
  location?: Maybe<LocationFilter>;
  search?: Maybe<Scalars['String']>;
};

export type EventQueryFilter = {
  time?: Maybe<Timefilter>;
};

export type EventReminder = {
  __typename?: 'EventReminder';
  alert: Scalars['Int'];
  alertSent: Scalars['Boolean'];
  createdAt: Scalars['DateTime'];
  event: Event;
  id: Scalars['ID'];
  updatedAt: Scalars['DateTime'];
};

export type EventResult = {
  __typename?: 'EventResult';
  results: Array<Event>;
  total: Scalars['Int'];
};

export type GoogleLoginArgs = {
  code: Scalars['String'];
};

export type HomeLocation = {
  __typename?: 'HomeLocation';
  city: Scalars['String'];
  houseNumber: Scalars['String'];
  latitude: Scalars['Float'];
  longitude: Scalars['Float'];
  street: Scalars['String'];
  zip: Scalars['String'];
};

export type ImportFinished = {
  __typename?: 'ImportFinished';
  importId: Scalars['ID'];
  importer: Scalars['String'];
};

export type Location = {
  __typename?: 'Location';
  latitude: Scalars['Float'];
  longitude: Scalars['Float'];
};

export type LocationFilter = {
  latitude: Scalars['Float'];
  longitude: Scalars['Float'];
  /** Radius in meter */
  radius: Scalars['Int'];
};

export type LocationWindow = {
  bottom: Scalars['Float'];
  left: Scalars['Float'];
  right: Scalars['Float'];
  top: Scalars['Float'];
};

export enum LoginType {
  Apple = 'APPLE',
  Email = 'EMAIL',
  Google = 'GOOGLE',
}

export type LoyaltyStatus = {
  __typename?: 'LoyaltyStatus';
  claimable: Scalars['Boolean'];
  lastClaimedAt?: Maybe<Scalars['DateTime']>;
  nextClaimableAt: Scalars['DateTime'];
};

export type Movie = {
  __typename?: 'Movie';
  createdAt: Scalars['DateTime'];
  description: Scalars['String'];
  fskRating?: Maybe<Scalars['Int']>;
  id: Scalars['ID'];
  poster?: Maybe<Scalars['String']>;
  runtime: Scalars['Int'];
  screenings: Array<MovieScreening>;
  title: Scalars['String'];
  updatedAt: Scalars['DateTime'];
};

export type MoviePosterArgs = {
  size: MoviePosterSize;
};

export type MovieScreeningsArgs = {
  filter?: Maybe<MovieScreeningsQueryFilter>;
};

export enum MoviePosterSize {
  ListThumb = 'LIST_THUMB',
  MoviePoster = 'MOVIE_POSTER',
}

export type MovieResult = {
  __typename?: 'MovieResult';
  results: Array<Movie>;
  total: Scalars['Int'];
};

export type MovieScreening = {
  __typename?: 'MovieScreening';
  auditorium?: Maybe<Scalars['String']>;
  createdAt: Scalars['DateTime'];
  id: Scalars['ID'];
  movie: Movie;
  theater: MovieTheater;
  ticketUrl?: Maybe<Scalars['String']>;
  time: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
};

export type MovieScreeningResult = {
  __typename?: 'MovieScreeningResult';
  results: Array<MovieScreening>;
  total: Scalars['Int'];
};

export type MovieScreeningsQueryFilter = {
  time?: Maybe<Timefilter>;
};

export type MovieTheater = {
  __typename?: 'MovieTheater';
  address: Address;
  contact: ContactInformation;
  createdAt: Scalars['DateTime'];
  description: Scalars['String'];
  id: Scalars['ID'];
  images: Array<Scalars['String']>;
  location: Location;
  logo?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  screenings: Array<MovieScreening>;
  updatedAt: Scalars['DateTime'];
};

export type MovieTheaterScreeningsArgs = {
  filter?: Maybe<MovieScreeningsQueryFilter>;
};

export type MovieTheaterResult = {
  __typename?: 'MovieTheaterResult';
  results: Array<MovieTheater>;
  total: Scalars['Int'];
};

export type MovieTheatersQueryFilter = {
  location?: Maybe<LocationFilter>;
};

export type Mutation = {
  __typename?: 'Mutation';
  addEventReminder: EventReminder;
  addFavoriteBikeStation: BikeStationFavorite;
  addFavoriteBusStop: BusStopFavorite;
  addFavoriteEventLocation: EventLocation;
  addFavoriteStore: Store;
  checkLoyalty: Scalars['Boolean'];
  deleteAccount: OkReply;
  login: Session;
  register: Session;
  registerLoyalty: Scalars['Boolean'];
  removeFavoriteBikeStation: BikeStationFavorite;
  removeFavoriteBusStop: BusStopFavorite;
  removeFavoriteEventLocation: EventLocation;
  removeFavoriteStore: Store;
  requestPasswordReset: OkReply;
  requestVerificationEmail: OkReply;
  setEmail: User;
  setHomeLocation: HomeLocation;
  setName: User;
  setPassword: User;
  setPushToken: OkReply;
  trackAdClick: OkReply;
  updateTrashAddress: TrashSetting;
  updateTrashNotification: TrashSetting;
  updateTrashType: TrashSetting;
  upsertTrashSettings: TrashSetting;
};

export type MutationAddEventReminderArgs = {
  alert: Scalars['Int'];
  eventId: Scalars['ID'];
};

export type MutationAddFavoriteBikeStationArgs = {
  bikeStationId: Scalars['Int'];
};

export type MutationAddFavoriteBusStopArgs = {
  busStopId: Scalars['Int'];
};

export type MutationAddFavoriteEventLocationArgs = {
  eventLocationId: Scalars['ID'];
};

export type MutationAddFavoriteStoreArgs = {
  storeId: Scalars['ID'];
};

export type MutationCheckLoyaltyArgs = {
  lastName: Scalars['String'];
  verificationData: Scalars['String'];
  verificationType: VerificationType;
};

export type MutationLoginArgs = {
  apple?: Maybe<AppleLoginArgs>;
  email?: Maybe<EmailLoginArgs>;
  google?: Maybe<GoogleLoginArgs>;
};

export type MutationRegisterArgs = {
  email: Scalars['String'];
  name: Scalars['String'];
  password: Scalars['String'];
};

export type MutationRegisterLoyaltyArgs = {
  bluecodeClaimToken: Scalars['String'];
  lastName: Scalars['String'];
  verificationData: Scalars['String'];
  verificationType: VerificationType;
};

export type MutationRemoveFavoriteBikeStationArgs = {
  bikeStationId: Scalars['Int'];
};

export type MutationRemoveFavoriteBusStopArgs = {
  busStopId: Scalars['Int'];
};

export type MutationRemoveFavoriteEventLocationArgs = {
  eventLocationId: Scalars['ID'];
};

export type MutationRemoveFavoriteStoreArgs = {
  storeId: Scalars['ID'];
};

export type MutationRequestPasswordResetArgs = {
  email: Scalars['String'];
};

export type MutationRequestVerificationEmailArgs = {
  email?: Maybe<Scalars['String']>;
};

export type MutationSetEmailArgs = {
  email: Scalars['String'];
};

export type MutationSetHomeLocationArgs = {
  city: Scalars['String'];
  houseNumber: Scalars['String'];
  street: Scalars['String'];
  zip: Scalars['String'];
};

export type MutationSetNameArgs = {
  name: Scalars['String'];
};

export type MutationSetPasswordArgs = {
  newPassword: Scalars['String'];
  oldPassword: Scalars['String'];
};

export type MutationSetPushTokenArgs = {
  token: Scalars['String'];
};

export type MutationTrackAdClickArgs = {
  viewId: Scalars['ID'];
};

export type MutationUpdateTrashAddressArgs = {
  cityId: Scalars['ID'];
  houseNumber: Scalars['String'];
  streetId: Scalars['ID'];
};

export type MutationUpdateTrashNotificationArgs = {
  time: Scalars['Int'];
  value: Scalars['Boolean'];
};

export type MutationUpdateTrashTypeArgs = {
  type: TrashType;
  value: Scalars['Boolean'];
};

export type MutationUpsertTrashSettingsArgs = {
  cityId: Scalars['ID'];
  houseNumber: Scalars['String'];
  notifications: Array<Scalars['Int']>;
  selectedTypes: Array<TrashType>;
  streetId: Scalars['ID'];
};

export type OkReply = {
  __typename?: 'OkReply';
  ok: Scalars['Boolean'];
};

export type Openings = {
  __typename?: 'Openings';
  friday: Array<Timespan>;
  monday: Array<Timespan>;
  saturday: Array<Timespan>;
  sunday: Array<Timespan>;
  thursday: Array<Timespan>;
  tuesday: Array<Timespan>;
  wednesday: Array<Timespan>;
};

export type OptionalAddress = {
  __typename?: 'OptionalAddress';
  city?: Maybe<Scalars['String']>;
  line1?: Maybe<Scalars['String']>;
  line2?: Maybe<Scalars['String']>;
  zipCode?: Maybe<Scalars['String']>;
};

export type OptionalLocation = {
  __typename?: 'OptionalLocation';
  latitude?: Maybe<Scalars['Float']>;
  longitude?: Maybe<Scalars['Float']>;
};

export type ParkingGarage = {
  __typename?: 'ParkingGarage';
  acceptsBluecode: Scalars['Boolean'];
  address: Address;
  alwaysOpen: Scalars['Boolean'];
  contact: ContactInformation;
  createdAt: Scalars['DateTime'];
  currentOpeningHours?: Maybe<Openings>;
  freeSpots?: Maybe<Scalars['Int']>;
  id: Scalars['ID'];
  images: Array<Scalars['String']>;
  location: Location;
  logo?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  open: Scalars['Boolean'];
  openingHours: Array<ParkingGarageOpeningHour>;
  timezone: Scalars['String'];
  updatedAt: Scalars['DateTime'];
};

export type ParkingGarageOpeningHour = {
  __typename?: 'ParkingGarageOpeningHour';
  createdAt: Scalars['DateTime'];
  id: Scalars['ID'];
  openings: Openings;
  updatedAt: Scalars['DateTime'];
  validFrom: Scalars['DateTime'];
  validUntil?: Maybe<Scalars['DateTime']>;
};

export type ParkingGarageQueryFilter = {
  acceptsBluecode?: Maybe<Scalars['Boolean']>;
  location?: Maybe<LocationFilter>;
};

export type ParkingGarageResult = {
  __typename?: 'ParkingGarageResult';
  results: Array<ParkingGarage>;
  total: Scalars['Int'];
};

export type PickupDate = {
  __typename?: 'PickupDate';
  createdAt: Scalars['DateTime'];
  date: Scalars['DateTime'];
  deviating: Scalars['Boolean'];
  id: Scalars['ID'];
  type: TrashType;
  updatedAt: Scalars['DateTime'];
};

export type PickupDateResult = {
  __typename?: 'PickupDateResult';
  results: Array<PickupDate>;
  total: Scalars['Int'];
};

export type Query = {
  __typename?: 'Query';
  BikeStation: BikeStation;
  BusStop: BusStop;
  Event: Event;
  EventCategory: EventCategory;
  EventLocation: EventLocation;
  EventLocationCategory: EventLocationCategory;
  Movie: Movie;
  MovieScreening: MovieScreening;
  MovieTheater: MovieTheater;
  ParkingGarage: ParkingGarage;
  Store: Store;
  StoreCategory: StoreCategory;
  allBikeStationFavorites: Array<BikeStationFavorite>;
  allBikeStations: BikeStationResult;
  allBusStopFavorites: Array<BusStopFavorite>;
  allBusStops: BusStopResult;
  allEventCategories: EventCategoryResult;
  allEventLocationCategories: EventLocationCategoryResult;
  allEventLocations: EventLocationResult;
  allEvents: EventResult;
  allMovieScreenings: MovieScreeningResult;
  allMovieTheaters: MovieTheaterResult;
  allMovies: MovieResult;
  allParkingGarages: ParkingGarageResult;
  allPickupDates: PickupDateResult;
  allStoreCategories: StoreCategoryResult;
  allStores: StoreResult;
  allTrashCities: TrashCityResult;
  allTripStops: TripStopResult;
  allWidgets: WidgetResult;
  homeLocation?: Maybe<HomeLocation>;
  loyaltyStatus: LoyaltyStatus;
  me: User;
  myTrashSettings: TrashSetting;
  randomAd?: Maybe<BannerAd>;
  randomAds: BannerAdResult;
  weather: Weather;
};

export type QueryBikeStationArgs = {
  id: Scalars['Int'];
};

export type QueryBusStopArgs = {
  id: Scalars['Int'];
};

export type QueryEventArgs = {
  id: Scalars['ID'];
};

export type QueryEventCategoryArgs = {
  id: Scalars['ID'];
};

export type QueryEventLocationArgs = {
  id: Scalars['ID'];
};

export type QueryEventLocationCategoryArgs = {
  id: Scalars['ID'];
};

export type QueryMovieArgs = {
  id: Scalars['ID'];
};

export type QueryMovieScreeningArgs = {
  id: Scalars['ID'];
};

export type QueryMovieTheaterArgs = {
  id: Scalars['ID'];
};

export type QueryParkingGarageArgs = {
  id: Scalars['ID'];
};

export type QueryStoreArgs = {
  id: Scalars['ID'];
};

export type QueryStoreCategoryArgs = {
  id: Scalars['ID'];
};

export type QueryAllBikeStationsArgs = {
  all?: Maybe<Scalars['Boolean']>;
  filter: AllBikeStationsFilter;
  first?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  sortDirection?: Maybe<SortDirection>;
  sortField?: Maybe<Scalars['String']>;
};

export type QueryAllBusStopsArgs = {
  all?: Maybe<Scalars['Boolean']>;
  filter: AllBusStopsFilter;
  first?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  sortDirection?: Maybe<SortDirection>;
  sortField?: Maybe<Scalars['String']>;
};

export type QueryAllEventCategoriesArgs = {
  all?: Maybe<Scalars['Boolean']>;
  first?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  sortDirection?: Maybe<SortDirection>;
  sortField?: Maybe<Scalars['String']>;
};

export type QueryAllEventLocationCategoriesArgs = {
  all?: Maybe<Scalars['Boolean']>;
  first?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  sortDirection?: Maybe<SortDirection>;
  sortField?: Maybe<Scalars['String']>;
};

export type QueryAllEventLocationsArgs = {
  all?: Maybe<Scalars['Boolean']>;
  filter?: Maybe<EventLocationsQueryFilter>;
  first?: Maybe<Scalars['Int']>;
  orderByNumberOfEvents?: Maybe<Scalars['Boolean']>;
  skip?: Maybe<Scalars['Int']>;
  sortDirection?: Maybe<SortDirection>;
  sortField?: Maybe<Scalars['String']>;
};

export type QueryAllEventsArgs = {
  all?: Maybe<Scalars['Boolean']>;
  filter?: Maybe<AllEventsQueryFilter>;
  first?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  sortDirection?: Maybe<SortDirection>;
  sortField?: Maybe<Scalars['String']>;
};

export type QueryAllMovieScreeningsArgs = {
  all?: Maybe<Scalars['Boolean']>;
  filter?: Maybe<AllMovieScreeningsQueryFilter>;
  first?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  sortDirection?: Maybe<SortDirection>;
  sortField?: Maybe<Scalars['String']>;
};

export type QueryAllMovieTheatersArgs = {
  all?: Maybe<Scalars['Boolean']>;
  filter?: Maybe<MovieTheatersQueryFilter>;
  first?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  sortDirection?: Maybe<SortDirection>;
  sortField?: Maybe<Scalars['String']>;
};

export type QueryAllMoviesArgs = {
  all?: Maybe<Scalars['Boolean']>;
  first?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  sortDirection?: Maybe<SortDirection>;
  sortField?: Maybe<Scalars['String']>;
};

export type QueryAllParkingGaragesArgs = {
  all?: Maybe<Scalars['Boolean']>;
  filter?: Maybe<ParkingGarageQueryFilter>;
  first?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  sortDirection?: Maybe<SortDirection>;
  sortField?: Maybe<Scalars['String']>;
};

export type QueryAllPickupDatesArgs = {
  all?: Maybe<Scalars['Boolean']>;
  first?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  sortDirection?: Maybe<SortDirection>;
  sortField?: Maybe<Scalars['String']>;
};

export type QueryAllStoreCategoriesArgs = {
  all?: Maybe<Scalars['Boolean']>;
  first?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  sortDirection?: Maybe<SortDirection>;
  sortField?: Maybe<Scalars['String']>;
};

export type QueryAllStoresArgs = {
  all?: Maybe<Scalars['Boolean']>;
  filter?: Maybe<StoreQueryFilter>;
  first?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  sortDirection?: Maybe<SortDirection>;
  sortField?: Maybe<Scalars['String']>;
};

export type QueryAllTrashCitiesArgs = {
  all?: Maybe<Scalars['Boolean']>;
  first?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  sortDirection?: Maybe<SortDirection>;
  sortField?: Maybe<Scalars['String']>;
};

export type QueryAllTripStopsArgs = {
  afterVisitNumber?: Maybe<Scalars['Int']>;
  all?: Maybe<Scalars['Boolean']>;
  first?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  sortDirection?: Maybe<SortDirection>;
  sortField?: Maybe<Scalars['String']>;
  tripId: Scalars['String'];
};

export type QueryAllWidgetsArgs = {
  all?: Maybe<Scalars['Boolean']>;
  first?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  sortDirection?: Maybe<SortDirection>;
  sortField?: Maybe<Scalars['String']>;
};

export type QueryRandomAdsArgs = {
  limit: Scalars['Int'];
};

export type Session = {
  __typename?: 'Session';
  token: Scalars['String'];
  user: User;
};

export enum SortDirection {
  Asc = 'ASC',
  Desc = 'DESC',
}

export type StationInformation = {
  __typename?: 'StationInformation';
  availableSpaces: Scalars['Int'];
  availableVehicles: Scalars['Int'];
};

export type Store = {
  __typename?: 'Store';
  acceptsBluecode: Scalars['Boolean'];
  acceptsCashback: Scalars['Boolean'];
  acceptsOwnLoyalty: Scalars['Boolean'];
  acceptsStampCard: Scalars['Boolean'];
  address: Address;
  categories: Array<StoreCategory>;
  contact: ContactInformation;
  createdAt: Scalars['DateTime'];
  currentOpeningHours?: Maybe<Openings>;
  id: Scalars['ID'];
  images: Array<Scalars['String']>;
  isFavorite: Scalars['Boolean'];
  isPremium: Scalars['Boolean'];
  location: Location;
  logo?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  openingHours: Array<StoreOpeningHour>;
  storeLinkItems: Array<StoreLinkItem>;
  storeNewsItems: Array<StoreNewsItem>;
  timezone: Scalars['String'];
  updatedAt: Scalars['DateTime'];
};

export type StoreCategory = {
  __typename?: 'StoreCategory';
  createdAt: Scalars['DateTime'];
  icon?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  name: Scalars['String'];
  stores: Array<Store>;
  updatedAt: Scalars['DateTime'];
};

export type StoreCategoryResult = {
  __typename?: 'StoreCategoryResult';
  results: Array<StoreCategory>;
  total: Scalars['Int'];
};

export type StoreLinkItem = {
  __typename?: 'StoreLinkItem';
  createdAt: Scalars['DateTime'];
  id: Scalars['ID'];
  store: Store;
  title: Scalars['String'];
  updatedAt: Scalars['DateTime'];
  url: Scalars['String'];
};

export type StoreNewsItem = {
  __typename?: 'StoreNewsItem';
  buttonText?: Maybe<Scalars['String']>;
  buttonUrl?: Maybe<Scalars['String']>;
  createdAt: Scalars['DateTime'];
  id: Scalars['ID'];
  store: Store;
  text: Scalars['String'];
  title: Scalars['String'];
  updatedAt: Scalars['DateTime'];
};

export type StoreOpeningHour = {
  __typename?: 'StoreOpeningHour';
  createdAt: Scalars['DateTime'];
  id: Scalars['ID'];
  openings: Openings;
  updatedAt: Scalars['DateTime'];
  validFrom: Scalars['DateTime'];
  validUntil?: Maybe<Scalars['DateTime']>;
};

export type StoreQueryFilter = {
  acceptsBluecode?: Maybe<Scalars['Boolean']>;
  categories?: Maybe<Array<Scalars['ID']>>;
  favorites?: Maybe<Scalars['Boolean']>;
  location?: Maybe<LocationFilter>;
  search?: Maybe<Scalars['String']>;
};

export type StoreResult = {
  __typename?: 'StoreResult';
  results: Array<Store>;
  total: Scalars['Int'];
};

export type Subscription = {
  __typename?: 'Subscription';
  importFinished: ImportFinished;
};

export type Temperature = {
  __typename?: 'Temperature';
  max: Scalars['Float'];
  min: Scalars['Float'];
};

export enum Timefilter {
  OnlyFuture = 'ONLY_FUTURE',
  OnlyPast = 'ONLY_PAST',
}

export type Timespan = {
  __typename?: 'Timespan';
  from: Scalars['Int'];
  to: Scalars['Int'];
};

export type TrashCity = {
  __typename?: 'TrashCity';
  createdAt: Scalars['DateTime'];
  id: Scalars['ID'];
  name: Scalars['String'];
  streets: Array<TrashStreet>;
  updatedAt: Scalars['DateTime'];
};

export type TrashCityResult = {
  __typename?: 'TrashCityResult';
  results: Array<TrashCity>;
  total: Scalars['Int'];
};

export type TrashSetting = {
  __typename?: 'TrashSetting';
  city: TrashCity;
  createdAt: Scalars['DateTime'];
  houseNumber: Scalars['String'];
  id: Scalars['ID'];
  notifications: Array<Scalars['Int']>;
  possibleHouseNumbers: Array<Scalars['String']>;
  selectedTypes: Array<TrashType>;
  street: TrashStreet;
  timezone: Scalars['String'];
  updatedAt: Scalars['DateTime'];
};

export type TrashStreet = {
  __typename?: 'TrashStreet';
  city: TrashCity;
  createdAt: Scalars['DateTime'];
  id: Scalars['ID'];
  name: Scalars['String'];
  updatedAt: Scalars['DateTime'];
};

export enum TrashType {
  OrganicWaste = 'ORGANIC_WASTE',
  PaperWaste = 'PAPER_WASTE',
  ResidualWaste = 'RESIDUAL_WASTE',
  YellowBin = 'YELLOW_BIN',
}

export type TripStop = {
  __typename?: 'TripStop';
  plannedTime: Scalars['DateTime'];
  stopPointName: Scalars['String'];
  track?: Maybe<Scalars['String']>;
  visitNumber: Scalars['Float'];
};

export type TripStopResult = {
  __typename?: 'TripStopResult';
  results: Array<TripStop>;
  total: Scalars['Int'];
};

export type User = {
  __typename?: 'User';
  createdAt: Scalars['DateTime'];
  email: Scalars['String'];
  id: Scalars['ID'];
  loginType: LoginType;
  name: Scalars['String'];
  trashSetting: TrashSetting;
  updatedAt: Scalars['DateTime'];
  verificationRequired?: Maybe<VerificationRequiredType>;
};

export enum VerificationRequiredType {
  No = 'NO',
  Soon = 'SOON',
  Yes = 'YES',
}

export enum VerificationType {
  Kundennummer = 'KUNDENNUMMER',
  Vertragsnummer = 'VERTRAGSNUMMER',
  Zaehlernummer = 'ZAEHLERNUMMER',
}

export type Weather = {
  __typename?: 'Weather';
  currentTemperature: Scalars['Float'];
  days: Array<DailyWeather>;
  locationName: Scalars['String'];
};

export enum WeatherIconName {
  W01d = 'W01d',
  W01n = 'W01n',
  W02d = 'W02d',
  W02n = 'W02n',
  W03d = 'W03d',
  W03n = 'W03n',
  W04d = 'W04d',
  W04n = 'W04n',
  W09d = 'W09d',
  W09n = 'W09n',
  W10d = 'W10d',
  W10n = 'W10n',
  W11d = 'W11d',
  W11n = 'W11n',
  W13d = 'W13d',
  W13n = 'W13n',
  W50d = 'W50d',
  W50n = 'W50n',
}

export type Widget = {
  __typename?: 'Widget';
  aspectRatio: Scalars['Float'];
  code: Scalars['String'];
  createdAt: Scalars['DateTime'];
  detailCode?: Maybe<Scalars['String']>;
  fullSize: Scalars['Boolean'];
  icon: Scalars['String'];
  id: Scalars['ID'];
  title: Scalars['String'];
  updatedAt: Scalars['DateTime'];
};

export type WidgetResult = {
  __typename?: 'WidgetResult';
  results: Array<Widget>;
  total: Scalars['Int'];
};

export type ImportFinishedSubscriptionVariables = Exact<{ [key: string]: never }>;

export type ImportFinishedSubscription = { __typename?: 'Subscription' } & {
  importFinished: { __typename?: 'ImportFinished' } & Pick<ImportFinished, 'importId'>;
};

export const ImportFinishedDocument = gql`
  subscription ImportFinished {
    importFinished {
      importId
    }
  }
`;

/**
 * __useImportFinishedSubscription__
 *
 * To run a query within a React component, call `useImportFinishedSubscription` and pass it any options that fit your needs.
 * When your component renders, `useImportFinishedSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useImportFinishedSubscription({
 *   variables: {
 *   },
 * });
 */
export function useImportFinishedSubscription(
  baseOptions?: Apollo.SubscriptionHookOptions<ImportFinishedSubscription, ImportFinishedSubscriptionVariables>,
) {
  return Apollo.useSubscription<ImportFinishedSubscription, ImportFinishedSubscriptionVariables>(
    ImportFinishedDocument,
    baseOptions,
  );
}
export type ImportFinishedSubscriptionHookResult = ReturnType<typeof useImportFinishedSubscription>;
export type ImportFinishedSubscriptionResult = Apollo.SubscriptionResult<ImportFinishedSubscription>;
export const namedOperations = {
  Subscription: {
    ImportFinished: 'ImportFinished',
  },
};
