import Collapse from '@material-ui/core/Collapse';
import List from '@material-ui/core/List';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';
import DefaultIcon from '@material-ui/icons/ViewList';
import React, { useState } from 'react';
import { MenuItemLink } from 'react-admin';

const MenuItem = ({ resource, sidebarOpen, pathname }: { resource: any; sidebarOpen: boolean; pathname: string }) => {
  const [collapsed, setCollaped] = useState(true);

  const renderSingle = () => (
    <div style={{ padding: '8px 0' }}>
      <MenuItemLink
        to={`/${resource.name}`}
        primaryText={resource.options.menuLabel || resource.options.label || resource.name}
        leftIcon={resource.icon ? <resource.icon /> : <DefaultIcon />}
        dense={false}
        sidebarIsOpen={sidebarOpen}
      />
    </div>
  );

  const renderMulti = () => (
    <List component="div">
      <div style={{ position: 'relative' }}>
        <div className="activeIndicator" />
        <MenuItemLink
          to={`/${resource.name}`}
          primaryText={resource.options.menuLabel || resource.options.label || resource.name}
          leftIcon={resource.icon ? <resource.icon /> : <DefaultIcon />}
          onClick={() => setCollaped(!collapsed)}
          dense={false}
          sidebarIsOpen={sidebarOpen}
        />
        <div
          style={{ position: 'absolute', right: 8, top: 4, cursor: 'pointer' }}
          onClick={() => setCollaped(!collapsed)}
        >
          {!collapsed ? <ExpandLess /> : <ExpandMore />}
        </div>
      </div>
      <Collapse in={!collapsed} timeout="auto" unmountOnExit>
        <List component="div" disablePadding style={{ paddingLeft: 16 }}>
          {resource.children.map((child: any) => (
            <MenuItemLink
              key={child.name}
              to={`/${child.name}`}
              primaryText={child.options.menuLabel || child.options.label || child.name}
              leftIcon={child.icon ? <child.icon /> : <DefaultIcon />}
              dense={false}
              sidebarIsOpen={sidebarOpen}
            />
          ))}
        </List>
      </Collapse>
    </List>
  );

  return resource.children && resource.children.length > 0 ? renderMulti() : renderSingle();
};

export default MenuItem;
