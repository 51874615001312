import InputLabel from '@material-ui/core/InputLabel';
import { makeStyles } from '@material-ui/core/styles';
import React from 'react';
import TimeInput from './TimeInput';

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  label: {
    marginRight: theme.spacing(2),
  },
  field: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    marginRight: theme.spacing(4),
  },
}));

const TimespanInput = ({ onChange, from, to }: { onChange: Function; from: number; to: number }) => {
  const classes = useStyles();

  const handleChange = (value: number, type: 'from' | 'to') => {
    if (Number.isNaN(value)) return onChange({ from, to, [type]: NaN });

    if (type === 'from') return onChange({ from: value, to: !Number.isNaN(to) && to > value ? to : value + 1 });
    if (type === 'to') return onChange({ from: !Number.isNaN(from) && from < value ? from : value - 1, to: value });
  };

  return (
    <div className={classes.container}>
      <div className={classes.field}>
        <InputLabel className={classes.label}>From</InputLabel>
        <TimeInput value={from} onChange={(value) => handleChange(value, 'from')} />
      </div>
      <div className={classes.field}>
        <InputLabel className={classes.label}>To</InputLabel>
        <TimeInput value={to} onChange={(value) => handleChange(value, 'to')} />
      </div>
    </div>
  );
};

export default TimespanInput;
