import { Resource } from 'ra-core';
import polyglotI18nProvider from 'ra-i18n-polyglot';
import englishMessages from 'ra-language-english';
import germanMessages from 'ra-language-german';
import treeGermanMessages from 'ra-tree-language-german';
import React from 'react';
import { Admin, Login, mergeTranslations } from 'react-admin';
import { ConflictIcon, ConflictList, ConflictShow } from './components/conflicts';
import Dashboard from './components/Dashboard';
import { ImportIcon, ImportList, ImportShow } from './components/import';
import LoginForm from './components/LoginForm';
import Menu from './components/Menu';
import resources from './resources';
import { createCreate } from './resources/create';
import { createEdit } from './resources/edit';
import { createList } from './resources/list';
import { createShow } from './resources/show';
import { createMenuIcon } from './resources/util';
import authProvider from './utils/auth-provider';
import { createDataProvider } from './utils/data-provider';

const LoginPage = () => (
  <Login style={{ background: '#272E3A' }}>
    <LoginForm />
  </Login>
);

const apiBaseUrl = window.config.CITYHUB_ADMIN_API_BASE_URL;

const i18nProvider = polyglotI18nProvider(
  () =>
    mergeTranslations(englishMessages, germanMessages, treeGermanMessages, {
      ra: { page: { empty: 'Noch keine %{name}', invite: 'Möchstest du einen neuen Eintrag hinzufügen?' } },
      resources: Object.fromEntries(
        resources.map((resource) => [resource.name, { empty: `Noch keine ${resource.labelPlural || resource.name}` }]),
      ),
    }),
  'de',
);

const App = () => (
  <Admin
    dashboard={Dashboard}
    menu={Menu}
    loginPage={LoginPage}
    dataProvider={createDataProvider({ apiBaseUrl })}
    authProvider={authProvider({ apiBaseUrl })}
    i18nProvider={i18nProvider}
    disableTelemetry
  >
    <Resource
      name="conflicts"
      icon={ConflictIcon}
      list={ConflictList}
      show={ConflictShow}
      options={{ menuLabel: 'Konflikte', label: 'Konflikte' }}
    />
    <Resource
      name="imports"
      icon={ImportIcon}
      list={ImportList}
      show={ImportShow}
      options={{ menuLabel: 'Import', label: 'Import' }}
    />

    {resources.map((r) => (
      <Resource
        key={r.name}
        name={r.name}
        icon={r.icon ? createMenuIcon(r.icon) : undefined}
        list={r.noList ? undefined : createList(r)}
        show={r.noShow ? undefined : createShow(r)}
        edit={r.updatable ? createEdit(r) : undefined}
        create={r.creatable ? createCreate(r) : undefined}
        options={{ menuLabel: r.menuLabel, label: r.labelPlural || r.label, parent: r.parent }}
      />
    ))}
  </Admin>
);

export default App;
