import { FieldType, ResourceDefinition } from './utils/types';

const resources: ResourceDefinition[] = [
  {
    name: 'push-notifications',
    label: 'Push Notifications',
    labelPlural: 'Push Notifications',
    menuLabel: 'Push Notifications',
    icon: 'Message',
    creatable: true,
    updatable: true,
    deletable: false,
    filters: [{ name: 'title||$contL', label: 'Titel', type: FieldType.String }, { name: 'text||$contL', label: 'Text', type: FieldType.String }],
    fields: [
      { name: 'id', type: FieldType.String, notEditable: true, hideInList: true },
      { name: 'title', label: 'Titel', type: FieldType.String },
      { name: 'text', label: 'Text', type: FieldType.String, longText: true },
      { name: 'scheduledFor', label: 'Geplant', type: FieldType.DateTime },
      { name: 'triggered', label: 'Gestartet', type: FieldType.Boolean, notEditable: true },
      { name: 'sent', label: 'Gesendet', type: FieldType.Boolean, notEditable: true },
      { name: 'errorMessage', label: 'Fehler', type: FieldType.String, notEditable: true, trimInList: 50, longText: true },
    ],
  },
  {
    name: 'widgets',
    label: 'Widgets',
    labelPlural: 'Widgets',
    menuLabel: 'Widgets',
    icon: 'Category',
    creatable: true,
    updatable: true,
    deletable: true,
    filters: [],
    fields: [
      { name: 'id', type: FieldType.String, notEditable: true, hideInList: true },
      { name: 'title', label: 'Titel', type: FieldType.String },
      { name: 'code', label: 'Code', type: FieldType.String, longText: true, hideInList: true },
      { name: 'detailCode', label: 'Code der Detailseite', type: FieldType.String, longText: true, hideInList: true },
      { name: 'fullSize', label: 'Volle Breite', type: FieldType.Boolean },
      { name: 'aspectRatio', label: 'Seitenverhältnis', type: FieldType.Number },
      {
        name: 'icon',
        label: 'Icon',
        type: FieldType.Image,
        hideInList: true,
        image: { width: 128, height: 128 },
      },
    ],
  },
  {
    name: 'users',
    label: 'Nutzer',
    labelPlural: 'Nutzer',
    menuLabel: 'Nutzer',
    icon: 'Person',
    creatable: false,
    updatable: true,
    deletable: false,
    filters: [{ name: 'name||$contL', label: 'Name', type: FieldType.String }],
    fields: [
      { name: 'id', type: FieldType.String, notEditable: true, hideInList: true },
      { name: 'name', label: 'Name', type: FieldType.String },
      {
        name: 'storeFavorites',
        label: 'Favorisierte Händler',
        type: FieldType.ManyReference,
        reference: { resource: 'stores', relationId: 'storeFavoriteIds', fieldName: 'name' },
        hideInList: true,
      },
      {
        name: 'eventLocationFavorites',
        label: 'Favorisierte Veranstaltungsorte',
        type: FieldType.ManyReference,
        reference: { resource: 'event-locations', relationId: 'eventLocationFavoriteIds', fieldName: 'name' },
        hideInList: true,
      },
      {
        name: 'eventReminders',
        label: 'Veranstaltungserinnerungen',
        type: FieldType.ManyReferenceList,
        reference: {
          resource: 'event-reminders',
          relationId: 'eventReminderIds',
          fields: [
            {
              name: 'event.id',
              label: 'Veranstaltung',
              type: FieldType.Reference,
              reference: { resource: 'events', relationId: 'eventId', fieldName: 'name' },
            },
            { name: 'alert', label: 'Erinnerung', type: FieldType.Number },
          ],
        },
        notEditable: true,
        hideInList: true,
      },
    ],
  },
  {
    name: 'settings',
    label: 'Einstellung',
    labelPlural: 'Einstellungen',
    menuLabel: 'Einstellungen',
    icon: 'Settings',
    creatable: false,
    updatable: true,
    deletable: false,
    fields: [
      { name: 'locationName', label: 'Standort-Name', type: FieldType.String },
      { name: 'location.latitude', label: 'Breitengrad', type: FieldType.Number, defaultValue: 47.785376 },
      { name: 'location.longitude', label: 'Längengrad', type: FieldType.Number, defaultValue: 9.607458 },
      {
        name: 'additional-map',
        label: 'Standort',
        type: FieldType.Map,
        hideInList: true,
        map: { latitudeSource: 'location.latitude', longitudeSource: 'location.longitude', zoom: 12 },
      },
    ],
  },
  {
    name: 'stores',
    label: 'Händler',
    labelPlural: 'Händler',
    menuLabel: 'Händler',
    icon: 'Store',
    creatable: true,
    updatable: true,
    deletable: true,
    generalTabLabel: 'Allgemein',
    filters: [
      { name: 'name||$contL', label: 'Name', type: FieldType.String },
      { name: 'isPremium||$eq', label: 'Premiumhändler', type: FieldType.Boolean },
      {
        name: 'categories.id||$in',
        label: 'Kategorien',
        type: FieldType.ManyReference,
        reference: { resource: 'store-categories', relationId: 'categoryIds', fieldName: 'name' },
      },
      // { name: 'address.city||$cont', label: 'Stadt', type: FieldType.String }, // TODO: does not work
    ],
    fields: [
      { name: 'id', type: FieldType.String, notEditable: true, hideInList: true },
      { name: 'name', type: FieldType.String },
      { name: 'googleId', label: 'Google ID', type: FieldType.String, hideInList: true },
      { name: 'isPremium', label: 'Premiumhändler', type: FieldType.Boolean },
      { name: 'acceptsBluecode', label: 'Akzeptiert Bluecode', type: FieldType.Boolean, hideInList: true },
      { name: 'acceptsStampCard', label: 'Akzeptiert Stempelpass', type: FieldType.Boolean, hideInList: true },
      { name: 'acceptsCashback', label: 'Akzeptiert Cashback', type: FieldType.Boolean, hideInList: true },
      {
        name: 'acceptsOwnLoyalty',
        label: 'Akzeptiert eigenes Loyalty-Programm',
        type: FieldType.Boolean,
        hideInList: true,
      },

      {
        name: 'categories',
        label: 'Kategorien',
        type: FieldType.ManyReference,
        reference: { resource: 'store-categories', relationId: 'categoryIds', fieldName: 'name' },
      },
      {
        name: 'address.line1',
        label: 'Straße und Hausnummer',
        type: FieldType.String,
        tab: 'Kontakt',
        hideInList: true,
      },
      { name: 'address.line2', label: 'Adresszusatz', type: FieldType.String, tab: 'Kontakt', hideInList: true },
      { name: 'address.zipCode', label: 'Postleitzahl', type: FieldType.String, tab: 'Kontakt', hideInList: true },
      { name: 'address.city', label: 'Stadt', type: FieldType.String, tab: 'Kontakt' },
      {
        name: 'contact.emailAddress',
        label: 'E-Mail-Adresse',
        type: FieldType.Email,
        tab: 'Kontakt',
        hideInList: true,
      },
      { name: 'contact.phoneNumber', label: 'Telefonnummer', type: FieldType.String, tab: 'Kontakt', hideInList: true },
      { name: 'contact.website', label: 'Webseite', type: FieldType.URL, tab: 'Kontakt', hideInList: true },
      { name: 'images', label: 'Bilder', type: FieldType.ImageList, tab: 'Bilder', hideInList: true },
      {
        name: 'timezone',
        label: 'Zeitzone',
        type: FieldType.String,
        tab: 'Standort',
        notEditable: true,
        hideInList: true,
      },
      {
        name: 'location.latitude',
        label: 'Breitengrad',
        tab: 'Standort',
        type: FieldType.Number,
        hideInList: true,
        defaultValue: 47.785376,
      },
      {
        name: 'location.longitude',
        label: 'Längengrad',
        tab: 'Standort',
        type: FieldType.Number,
        hideInList: true,
        defaultValue: 9.607458,
      },
      {
        name: 'additional-map',
        label: 'Standort',
        type: FieldType.Map,
        tab: 'Standort',
        hideInList: true,
        map: { latitudeSource: 'location.latitude', longitudeSource: 'location.longitude', zoom: 12 },
      },
    ],
  },
  {
    name: 'store-categories',
    label: 'Händler-Kategorie',
    labelPlural: 'Händler-Kategorien',
    menuLabel: 'Kategorien',
    icon: 'Category',
    parent: 'stores',
    creatable: true,
    updatable: true,
    deletable: true,
    filters: [{ name: 'name||$contL', label: 'Name', type: FieldType.String }],
    fields: [
      { name: 'id', type: FieldType.String, notEditable: true, hideInList: true },
      { name: 'name', type: FieldType.String },
    ],
  },
  {
    name: 'store-opening-hours',
    label: 'Händler-Öffnungszeit',
    labelPlural: 'Händler-Öffnungszeiten',
    menuLabel: 'Öffnungszeiten',
    icon: 'Alarm',
    parent: 'stores',
    creatable: true,
    updatable: true,
    deletable: true,
    filters: [
      // TODO: date filter
      {
        name: 'storeId||$eq',
        label: 'Händler',
        type: FieldType.Reference,
        reference: { resource: 'stores', relationId: 'storeId', fieldName: 'name' },
      },
    ],
    fields: [
      { name: 'id', type: FieldType.String, notEditable: true, hideInList: true },
      {
        name: 'store.id',
        label: 'Händler',
        type: FieldType.Reference,
        reference: { resource: 'stores', relationId: 'storeId', fieldName: 'name' },
      },
      { name: 'validFrom', label: 'Gültig ab', type: FieldType.DateTime },
      { name: 'validUntil', label: 'Gültig bis', type: FieldType.DateTime },
      { name: 'openings', label: 'Öffnungszeiten', type: FieldType.OpeningHours, hideInList: true },
    ],
  },
  {
    name: 'store-news-items',
    label: 'Händler-News',
    labelPlural: 'Händler-News',
    menuLabel: 'News',
    icon: 'Book', // MenuBook, ImportContacts, Book
    parent: 'stores',
    creatable: true,
    updatable: true,
    deletable: true,
    filters: [
      { name: 'title||$contL', label: 'Titel', type: FieldType.String },
      {
        name: 'storeId||$eq',
        label: 'Händler',
        type: FieldType.Reference,
        reference: { resource: 'stores', relationId: 'storeId', fieldName: 'name' },
      },
    ],
    fields: [
      { name: 'id', type: FieldType.String, notEditable: true, hideInList: true },
      { name: 'title', label: 'Titel', type: FieldType.String },
      { name: 'text', label: 'Text', type: FieldType.String, hideInList: true, longText: true },
      { name: 'buttonText', label: 'Button-Text', type: FieldType.String, hideInList: true },
      { name: 'buttonUrl', label: 'Button-Link', type: FieldType.URL, hideInList: true },
      {
        name: 'store.id',
        label: 'Händler',
        type: FieldType.Reference,
        reference: { resource: 'stores', relationId: 'storeId', fieldName: 'name' },
      },
    ],
  },
  {
    name: 'store-link-items',
    label: 'Händler-Links',
    labelPlural: 'Händler-Links',
    menuLabel: 'Links',
    icon: 'ImportContacts', // MenuBook, ImportContacts, Book
    parent: 'stores',
    creatable: true,
    updatable: true,
    deletable: true,
    filters: [
      { name: 'title||$contL', label: 'Titel', type: FieldType.String },
      {
        name: 'storeId||$eq',
        label: 'Händler',
        type: FieldType.Reference,
        reference: { resource: 'stores', relationId: 'storeId', fieldName: 'name' },
      },
    ],
    fields: [
      { name: 'id', type: FieldType.String, notEditable: true, hideInList: true },
      { name: 'title', label: 'Titel', type: FieldType.String },
      { name: 'url', label: 'Link', type: FieldType.URL, hideInList: true },
      {
        name: 'store.id',
        label: 'Händler',
        type: FieldType.Reference,
        reference: { resource: 'stores', relationId: 'storeId', fieldName: 'name' },
      },
    ],
  },
  {
    name: 'banner-ads',
    label: 'Werbebanner',
    labelPlural: 'Werbebanner',
    menuLabel: 'Werbebanner',
    icon: 'Image',
    parent: 'stores',
    creatable: true,
    updatable: true,
    deletable: true,
    filters: [
      // TODO: date filter
      { name: 'name||$contL', label: 'Name', type: FieldType.String },
      {
        name: 'type||$eq',
        label: 'Type',
        type: FieldType.RadioGroup,
        choices: [
          { id: 'IMAGE', name: 'Bild' },
          { id: 'TEXT', name: 'Text' },
          { id: 'IMAGE_TEXT', name: 'Bild und Text' },
        ],
      },
      {
        name: 'storeId||$eq',
        label: 'Händler',
        type: FieldType.Reference,
        reference: { resource: 'stores', relationId: 'storeId', fieldName: 'name' },
      },
    ],
    fields: [
      { name: 'id', type: FieldType.String, notEditable: true, hideInList: true },
      { name: 'name', type: FieldType.String },
      { name: 'link', type: FieldType.URL, hideInList: true },
      {
        name: 'type',
        type: FieldType.RadioGroup,
        choices: [
          { id: 'IMAGE', name: 'Bild' },
          { id: 'TEXT', name: 'Text' },
          { id: 'IMAGE_TEXT', name: 'Bild und Text' },
        ],
      },
      {
        name: 'image',
        label: 'Bild',
        type: FieldType.Image,
        hideInList: true,
        dependsOn: (data) => data.type === 'IMAGE',
        image: { width: 1200, height: 440 },
      },
      {
        name: 'image',
        label: 'Bild',
        type: FieldType.Image,
        hideInList: true,
        dependsOn: (data) => data.type === 'IMAGE_TEXT',
        image: { width: 1200, height: 360 },
      },
      {
        name: 'text',
        type: FieldType.String,
        hideInList: true,
        dependsOn: (data) => data.type === 'TEXT' || data.type === 'IMAGE_TEXT',
        longText: true,
      },
      {
        name: 'buttonText',
        label: 'Button-Text',
        type: FieldType.String,
        hideInList: true,
        dependsOn: (data) => data.type === 'TEXT' || data.type === 'IMAGE_TEXT',
      },
      {
        name: 'store.id',
        label: 'Händler',
        type: FieldType.Reference,
        reference: { resource: 'stores', relationId: 'storeId', fieldName: 'name' },
      },
      { name: 'validFrom', label: 'Gültig ab', type: FieldType.DateTime },
      { name: 'validUntil', label: 'Gültig bis', type: FieldType.DateTime },
      { name: 'monthlyViewTarget', label: 'Monthly View Target', type: FieldType.Number },
    ],
  },
  {
    name: 'events',
    label: 'Veranstaltung',
    labelPlural: 'Veranstaltungen',
    menuLabel: 'Veranstaltungen',
    icon: 'Event',
    creatable: true,
    updatable: true,
    deletable: true,
    filters: [
      // TODO: date filter
      { name: 'name||$contL', label: 'Name', type: FieldType.String },
      { name: 'hidden||$eq', label: 'Versteckt', type: FieldType.Boolean },
      {
        name: 'categories.id||$in',
        label: 'Kategorien',
        type: FieldType.ManyReference,
        reference: { resource: 'event-categories', relationId: 'categoryIds', fieldName: 'name' },
      },
      {
        name: 'locationId||$eq',
        label: 'Veranstaltungsort',
        type: FieldType.Reference,
        reference: { resource: 'event-locations', relationId: 'locationId', fieldName: 'name' },
      },
    ],
    fields: [
      { name: 'id', type: FieldType.String, notEditable: true, hideInList: true },
      { name: 'name', type: FieldType.String },
      { name: 'description', label: 'Beschreibung', type: FieldType.String, hideInList: true, longText: true },
      { name: 'image', label: 'Bild', type: FieldType.Image, hideInList: true },
      { name: 'begin', label: 'Beginn', type: FieldType.DateTime },
      { name: 'end', label: 'Ende', type: FieldType.DateTime },
      { name: 'hidden', label: 'Versteckt', type: FieldType.Boolean },
      {
        name: 'location.id',
        label: 'Veranstaltungsort',
        type: FieldType.Reference,
        reference: { resource: 'event-locations', relationId: 'locationId', fieldName: 'name' },
      },
      {
        name: 'categories',
        label: 'Kategorien',
        type: FieldType.ManyReference,
        reference: { resource: 'event-categories', relationId: 'categoryIds', fieldName: 'name' },
      },
    ],
  },
  {
    name: 'event-categories',
    label: 'Veranstaltungs-Kategorie',
    labelPlural: 'Veranstaltungs-Kategorien',
    menuLabel: 'Kategorien',
    icon: 'Category',
    parent: 'events',
    creatable: true,
    updatable: true,
    deletable: true,
    filters: [{ name: 'name||$contL', label: 'Name', type: FieldType.String }],
    fields: [
      { name: 'id', type: FieldType.String, notEditable: true, hideInList: true },
      { name: 'name', type: FieldType.String },
    ],
  },
  {
    name: 'event-locations',
    label: 'Veranstaltungsort',
    labelPlural: 'Veranstaltungsorte',
    menuLabel: 'Veranstaltungsorte',
    icon: 'LocationOn',
    creatable: true,
    updatable: true,
    deletable: true,
    generalTabLabel: 'Allgemein',
    filters: [
      { name: 'name||$contL', label: 'Name', type: FieldType.String },
      {
        name: 'categories.id||$in',
        label: 'Kategorien',
        type: FieldType.ManyReference,
        reference: { resource: 'event-location-categories', relationId: 'categoryIds', fieldName: 'name' },
      },
      // { name: 'address.city||$contL', label: 'Stadt', type: FieldType.Boolean },
    ],
    fields: [
      { name: 'id', type: FieldType.String, notEditable: true, hideInList: true },
      { name: 'name', type: FieldType.String },
      { name: 'description', label: 'Beschreibung', type: FieldType.String, hideInList: true, longText: true },
      { name: 'address.line1', label: 'Straße und Hausnummer', type: FieldType.String, hideInList: true },
      { name: 'address.line2', label: 'Adresszusatz', type: FieldType.String, hideInList: true },
      { name: 'address.zipCode', label: 'Postleitzahl', type: FieldType.String, hideInList: true },
      { name: 'address.city', label: 'Stadt', type: FieldType.String },
      { name: 'contact.emailAddress', label: 'E-Mail-Adresse', type: FieldType.Email, hideInList: true },
      { name: 'contact.phoneNumber', label: 'Telefonnummer', type: FieldType.String, hideInList: true },
      { name: 'contact.website', label: 'Webseite', type: FieldType.URL, hideInList: true },
      {
        name: 'categories',
        label: 'Kategorien',
        type: FieldType.ManyReference,
        reference: { resource: 'event-location-categories', relationId: 'categoryIds', fieldName: 'name' },
      },
      { name: 'images', label: 'Bilder', type: FieldType.ImageList, tab: 'Bilder', hideInList: true },
      {
        name: 'location.latitude',
        label: 'Breitengrad',
        tab: 'Standort',
        type: FieldType.Number,
        hideInList: true,
        defaultValue: 47.785376,
      },
      {
        name: 'location.longitude',
        label: 'Längengrad',
        tab: 'Standort',
        type: FieldType.Number,
        hideInList: true,
        defaultValue: 9.607458,
      },
      {
        name: 'additional-map',
        label: 'Standort',
        type: FieldType.Map,
        tab: 'Standort',
        hideInList: true,
        map: { latitudeSource: 'location.latitude', longitudeSource: 'location.longitude', zoom: 12 },
      },
    ],
  },
  {
    name: 'event-location-categories',
    label: 'Veranstaltungsort-Kategorie',
    labelPlural: 'Veranstaltungsort-Kategorien',
    menuLabel: 'Kategorien',
    icon: 'Category',
    parent: 'event-locations',
    creatable: true,
    updatable: true,
    deletable: true,
    filters: [{ name: 'name||$contL', label: 'Name', type: FieldType.String }],
    fields: [
      { name: 'id', type: FieldType.String, notEditable: true, hideInList: true },
      { name: 'name', type: FieldType.String },
    ],
  },
  {
    name: 'event-location-opening-hours',
    label: 'Veranstaltungsort-Öffnungszeit',
    labelPlural: 'Veranstaltungsort-Öffnungszeiten',
    menuLabel: 'Öffnungszeiten',
    icon: 'Alarm',
    parent: 'event-locations',
    creatable: true,
    updatable: true,
    deletable: true,
    filters: [
      // TODO: date filter
      {
        name: 'eventLocationId||$eq',
        label: 'Veranstaltungsort',
        type: FieldType.Reference,
        reference: { resource: 'event-locations', relationId: 'eventLocationId', fieldName: 'name' },
      },
    ],
    fields: [
      { name: 'id', type: FieldType.String, notEditable: true, hideInList: true },
      {
        name: 'eventLocation.id',
        label: 'Veranstaltungsort',
        type: FieldType.Reference,
        reference: { resource: 'event-locations', relationId: 'eventLocationId', fieldName: 'name' },
      },
      { name: 'validFrom', label: 'Gültig ab', type: FieldType.DateTime },
      { name: 'validUntil', label: 'Gültig bis', type: FieldType.DateTime },
      { name: 'openings', label: 'Öffnungszeiten', type: FieldType.OpeningHours, hideInList: true },
    ],
  },

  {
    name: 'movies',
    label: 'Kinofilm',
    labelPlural: 'Kinofilme',
    menuLabel: 'Kinofilme',
    icon: 'Movie',
    creatable: true,
    updatable: true,
    deletable: true,
    filters: [{ name: 'title||$contL', label: 'Filmtitel', type: FieldType.String }],
    fields: [
      { name: 'id', type: FieldType.String, notEditable: true, hideInList: true },
      { name: 'title', label: 'Filmtitel', type: FieldType.String },
      {
        name: 'description',
        label: 'Beschreibung',
        type: FieldType.String,
        hideInList: true,
        trimInList: 200,
        longText: true,
      },
      { name: 'runtime', label: 'Filmlänge', type: FieldType.Number },
      { name: 'fskRating', label: 'FSK Altersfreigabe', type: FieldType.Number },
      { name: 'poster', type: FieldType.Image, hideInList: true },
    ],
  },
  {
    name: 'movie-theaters',
    label: 'Kino',
    labelPlural: 'Kinos',
    menuLabel: 'Kinos',
    icon: 'Theaters',
    parent: 'movies',
    creatable: true,
    updatable: true,
    deletable: true,
    generalTabLabel: 'Allgemein',
    filters: [
      { name: 'name||$contL', label: 'Kinoname', type: FieldType.String },
      // { name: 'address.city||$contL', label: 'Stadt', type: FieldType.String },
    ],
    fields: [
      { name: 'id', type: FieldType.String, notEditable: true, hideInList: true },
      { name: 'name', label: 'Kinoname', type: FieldType.String },
      { name: 'description', label: 'Beschreibung', type: FieldType.String, hideInList: true, longText: true },
      { name: 'address.line1', label: 'Straße und Hausnummer', type: FieldType.String, hideInList: true },
      { name: 'address.line2', label: 'Adresszusatz', type: FieldType.String, hideInList: true },
      { name: 'address.zipCode', label: 'Postleitzahl', type: FieldType.String, hideInList: true },
      { name: 'address.city', label: 'Stadt', type: FieldType.String },
      { name: 'contact.emailAddress', label: 'E-Mail-Adresse', type: FieldType.Email, hideInList: true },
      { name: 'contact.phoneNumber', label: 'Telefonnummer', type: FieldType.String, hideInList: true },
      { name: 'contact.website', label: 'Webseite', type: FieldType.URL, hideInList: true },
      { name: 'images', label: 'Bilder', type: FieldType.ImageList, tab: 'Bilder', hideInList: true },
      {
        name: 'location.latitude',
        label: 'Breitengrad',
        tab: 'Standort',
        type: FieldType.Number,
        hideInList: true,
        defaultValue: 47.785376,
      },
      {
        name: 'location.longitude',
        label: 'Längengrad',
        tab: 'Standort',
        type: FieldType.Number,
        hideInList: true,
        defaultValue: 9.607458,
      },
      {
        name: 'additional-map',
        label: 'Standort',
        type: FieldType.Map,
        tab: 'Standort',
        hideInList: true,
        map: { latitudeSource: 'location.latitude', longitudeSource: 'location.longitude', zoom: 12 },
      },
    ],
  },
  {
    name: 'movie-screenings',
    label: 'Filmvorführung',
    labelPlural: 'Filmvorführungen',
    menuLabel: 'Filmvorführungen',
    icon: 'PlayCircleFilled',
    parent: 'movies',
    creatable: true,
    updatable: true,
    deletable: true,
    filters: [
      // TODO: add date filter
      {
        name: 'movieId||$eq',
        label: 'Kinofilm',
        type: FieldType.Reference,
        reference: { resource: 'movies', relationId: 'movieId', fieldName: 'title' },
      },
      {
        name: 'theaterId||$eq',
        label: 'Kino',
        type: FieldType.Reference,
        reference: { resource: 'movie-theaters', relationId: 'theaterId', fieldName: 'name' },
      },
    ],
    fields: [
      { name: 'id', type: FieldType.String, notEditable: true, hideInList: true },
      {
        name: 'movie.id',
        label: 'Kinofilm',
        type: FieldType.Reference,
        reference: { resource: 'movies', relationId: 'movieId', fieldName: 'title' },
      },
      {
        name: 'theater.id',
        label: 'Kino',
        type: FieldType.Reference,
        reference: { resource: 'movie-theaters', relationId: 'theaterId', fieldName: 'name' },
      },
      { name: 'time', label: 'Uhrzeit', type: FieldType.DateTime },
      { name: 'ticketUrl', label: 'URL für Ticketreservierung', type: FieldType.URL, hideInList: true },
      { name: 'auditorium', label: 'Kinosaal', type: FieldType.String, hideInList: true },
    ],
  },
  {
    name: 'bus-stop-favorites',
    label: 'Favorisierte Bushaltestelle',
    labelPlural: 'Favorisierte Bushaltestellen',
    menuLabel: 'Fav. Bushaltestellen',
    icon: 'DirectionsBus',
    creatable: true,
    updatable: false,
    deletable: true,
    filters: [
      {
        name: 'userId||$eq',
        label: 'Nutzer',
        type: FieldType.Reference,
        reference: { resource: 'users', relationId: 'userId', fieldName: 'name' },
      },
    ],
    fields: [
      { name: 'id', type: FieldType.String, notEditable: true, hideInList: true },
      {
        name: 'busStopId',
        label: 'Bushaltestelle',
        type: FieldType.Reference,
        editType: FieldType.Number,
        reference: { resource: 'bus-stops', relationId: 'busStopId', fieldName: 'name' },
      },
      {
        name: 'user.id',
        label: 'Nutzer',
        type: FieldType.Reference,
        reference: { resource: 'users', relationId: 'userId', fieldName: 'name' },
      },
    ],
  },
  {
    name: 'bike-station-favorites',
    label: 'Favorisierte E-Bike-Station',
    labelPlural: 'Favorisierte E-Bike-Stationen',
    menuLabel: 'Fav. E-Bike-Stationen',
    icon: 'DirectionsBike',
    creatable: true,
    updatable: false,
    deletable: true,
    filters: [
      {
        name: 'userId||$eq',
        label: 'Nutzer',
        type: FieldType.Reference,
        reference: { resource: 'users', relationId: 'userId', fieldName: 'name' },
      },
    ],
    fields: [
      { name: 'id', type: FieldType.String, notEditable: true, hideInList: true },
      {
        name: 'bikeStationId',
        label: 'E-Bike-Station',
        type: FieldType.Reference,
        editType: FieldType.Number,
        reference: { resource: 'bike-stations', relationId: 'bikeStationId', fieldName: 'name' },
      },
      {
        name: 'user.id',
        label: 'Nutzer',
        type: FieldType.Reference,
        reference: { resource: 'users', relationId: 'userId', fieldName: 'name' },
      },
    ],
  },
  {
    name: 'parking-garages',
    label: 'Parkhaus',
    labelPlural: 'Parkhäuser',
    menuLabel: 'Parkhäuser',
    icon: 'LocalParking',
    creatable: true,
    updatable: true,
    deletable: true,
    generalTabLabel: 'Allgemein',
    filters: [
      { name: 'name||$contL', label: 'Name', type: FieldType.String },
      // { name: 'address.city||$contL', label: 'Stadt', type: FieldType.String },
    ],
    fields: [
      { name: 'id', type: FieldType.String, notEditable: true, hideInList: true },
      { name: 'name', type: FieldType.String },
      { name: 'liveDataName', type: FieldType.String, hideInList: true },
      { name: 'acceptsBluecode', label: 'Akzeptiert Bluecode', type: FieldType.Boolean, hideInList: true },
      { name: 'skipLiveData', type: FieldType.Boolean, hideInList: true },
      { name: 'state.PHState', label: 'Zustand', type: FieldType.Number, notEditable: true },
      { name: 'state.ShortMax', label: 'Maximale Kapazität', type: FieldType.Number, notEditable: true },
      { name: 'state.ShortFree', label: 'Aktuell freie Parkplätze', type: FieldType.Number, notEditable: true },
      { name: 'alwaysOpen', label: 'Durchgehend geöffnet', type: FieldType.Boolean, hideInList: true },
      { name: 'address.line1', label: 'Straße und Hausnummer', type: FieldType.String, hideInList: true },
      { name: 'address.line2', label: 'Adresszusatz', type: FieldType.String, hideInList: true },
      { name: 'address.zipCode', label: 'Postleitzahl', type: FieldType.String, hideInList: true },
      { name: 'address.city', label: 'Stadt', type: FieldType.String },
      { name: 'contact.emailAddress', label: 'E-Mail-Adresse', type: FieldType.Email, hideInList: true },
      { name: 'contact.phoneNumber', label: 'Telefonnummer', type: FieldType.String, hideInList: true },
      { name: 'contact.website', label: 'Webseite', type: FieldType.URL, hideInList: true },
      { name: 'images', label: 'Bilder', type: FieldType.ImageList, tab: 'Bilder', hideInList: true },
      {
        name: 'timezone',
        label: 'Zeitzone',
        type: FieldType.String,
        tab: 'Standort',
        notEditable: true,
        hideInList: true,
      },
      {
        name: 'location.latitude',
        label: 'Breitengrad',
        tab: 'Standort',
        type: FieldType.Number,
        hideInList: true,
        defaultValue: 47.785376,
      },
      {
        name: 'location.longitude',
        label: 'Längengrad',
        tab: 'Standort',
        type: FieldType.Number,
        hideInList: true,
        defaultValue: 9.607458,
      },
      {
        name: 'additional-map',
        label: 'Standort',
        type: FieldType.Map,
        tab: 'Standort',
        hideInList: true,
        map: { latitudeSource: 'location.latitude', longitudeSource: 'location.longitude', zoom: 12 },
      },
    ],
  },
  {
    name: 'parking-garage-opening-hours',
    label: 'Parkhaus-Öffnungszeit',
    labelPlural: 'Parkhaus-Öffnungszeiten',
    menuLabel: 'Öffnungszeiten',
    icon: 'Alarm',
    parent: 'parking-garages',
    creatable: true,
    updatable: true,
    deletable: true,
    filters: [
      // TODO: date filter
      {
        name: 'parkingGarageId||$eq',
        label: 'Parkhaus',
        type: FieldType.Reference,
        reference: { resource: 'parking-garages', relationId: 'parkingGarageId', fieldName: 'name' },
      },
    ],
    fields: [
      { name: 'id', type: FieldType.String, notEditable: true, hideInList: true },
      {
        name: 'parkingGarage.id',
        label: 'Parkhaus',
        type: FieldType.Reference,
        reference: { resource: 'parking-garages', relationId: 'parkingGarageId', fieldName: 'name' },
      },
      { name: 'validFrom', label: 'Gültig ab', type: FieldType.DateTime },
      { name: 'validUntil', label: 'Gültig bis', type: FieldType.DateTime },
      { name: 'openings', label: 'Öffnungszeiten', type: FieldType.OpeningHours, hideInList: true },
    ],
  },
  {
    name: 'trash-settings',
    label: 'Abfalleinstellung',
    labelPlural: 'Abfalleinstellungen',
    menuLabel: 'Abfall',
    icon: 'DeleteOutline',
    creatable: true,
    updatable: true,
    deletable: true,
    filters: [
      {
        name: 'userId||$eq',
        label: 'Nutzer',
        type: FieldType.Reference,
        reference: { resource: 'users', relationId: 'userId', fieldName: 'name' },
      },
      {
        name: 'cityId||$eq',
        label: 'Stadt',
        type: FieldType.Reference,
        reference: { resource: 'trash-cities', relationId: 'cityId', fieldName: 'name' },
      },
      {
        name: 'streetId||$eq',
        label: 'Straße',
        type: FieldType.Reference,
        reference: { resource: 'trash-streets', relationId: 'streetId', fieldName: 'name' },
      },
      { name: 'houseNumber||$contL', label: 'Hausnummer', type: FieldType.String },
      // TODO: fix this
      // {
      //   name: 'selectedTypes||$in',
      //   label: 'Abfallarten',
      //   type: FieldType.MultiSelect,
      //   choices: [
      //     { id: 'RESIDUAL_WASTE', name: 'Restmülltonne' },
      //     { id: 'PAPER_WASTE', name: 'Papiertonne' },
      //     { id: 'ORGANIC_WASTE', name: 'Biotonne' },
      //     { id: 'YELLOW_BIN', name: 'Gelbe Tonne' },
      //   ],
      // },
    ],
    fields: [
      { name: 'id', type: FieldType.String, notEditable: true, hideInList: true },
      {
        name: 'user.id',
        label: 'Nutzer',
        type: FieldType.Reference,
        reference: { resource: 'users', relationId: 'userId', fieldName: 'name' },
      },
      {
        name: 'city.id',
        label: 'Stadt',
        type: FieldType.Reference,
        reference: { resource: 'trash-cities', relationId: 'cityId', fieldName: 'name' },
      },
      {
        name: 'street.id',
        label: 'Straße',
        type: FieldType.Reference,
        reference: { resource: 'trash-streets', relationId: 'streetId', fieldName: 'name' },
      },
      { name: 'houseNumber', label: 'Hausnummer', type: FieldType.String },
      {
        name: 'selectedTypes',
        label: 'Abfallarten',
        type: FieldType.MultiSelect,
        choices: [
          { id: 'RESIDUAL_WASTE', name: 'Restmülltonne' },
          { id: 'PAPER_WASTE', name: 'Papiertonne' },
          { id: 'ORGANIC_WASTE', name: 'Biotonne' },
          { id: 'YELLOW_BIN', name: 'Gelbe Tonne' },
        ],
      },
      {
        name: 'residual.id',
        label: 'Restmülltonne Entleerungen',
        type: FieldType.ManyReference,
        reference: { resource: 'pickup-dates', relationId: 'residualIds', fieldName: 'date' },
        dependsOn: (data) => data.selectedTypes.indexOf('RESIDUAL_WASTE') !== -1,
        notEditable: true,
        hideInList: true,
      },
      {
        name: 'paper.id',
        label: 'Papiertonne Entleerungen',
        type: FieldType.ManyReference,
        reference: { resource: 'pickup-dates', relationId: 'paperIds', fieldName: 'date' },
        dependsOn: (data) => data.selectedTypes.indexOf('PAPER_WASTE') !== -1,
        notEditable: true,
        hideInList: true,
      },
      {
        name: 'organic.id',
        label: 'Biotonne Entleerungen',
        type: FieldType.ManyReference,
        reference: { resource: 'pickup-dates', relationId: 'organicIds', fieldName: 'date' },
        dependsOn: (data) => data.selectedTypes.indexOf('ORGANIC_WASTE') !== -1,
        notEditable: true,
        hideInList: true,
      },
      {
        name: 'yellow.id',
        label: 'Gelbe Tonne Entleerungen',
        type: FieldType.ManyReference,
        reference: { resource: 'pickup-dates', relationId: 'organicIds', fieldName: 'date' },
        dependsOn: (data) => data.selectedTypes.indexOf('YELLOW_BIN') !== -1,
        notEditable: true,
        hideInList: true,
      },
    ],
  },
  {
    name: 'trash-cities',
    label: 'Stadt',
    labelPlural: 'Städte',
    menuLabel: 'Städte',
    icon: 'LocationCity',
    parent: 'trash-settings',
    creatable: false,
    updatable: false,
    deletable: false,
    filters: [{ name: 'name||$contL', label: 'Name', type: FieldType.String }],
    fields: [
      { name: 'id', type: FieldType.String, notEditable: true, hideInList: true },
      { name: 'name', type: FieldType.String },
    ],
  },
  {
    name: 'trash-streets',
    label: 'Straße',
    labelPlural: 'Straßen',
    menuLabel: 'Straßen',
    icon: 'Directions',
    parent: 'trash-settings',
    creatable: false,
    updatable: false,
    deletable: false,
    filters: [
      {
        name: 'cityId||eq',
        label: 'Stadt',
        type: FieldType.Reference,
        reference: { resource: 'trash-cities', relationId: 'cityId', fieldName: 'name' },
      },
      { name: 'name||$contL', label: 'Name', type: FieldType.String },
    ],
    fields: [
      { name: 'id', type: FieldType.String, notEditable: true, hideInList: true },
      { name: 'name', type: FieldType.String },
      {
        name: 'city.id',
        label: 'Stadt',
        type: FieldType.Reference,
        reference: { resource: 'trash-cities', relationId: 'cityId', fieldName: 'name' },
      },
    ],
  },
  {
    name: 'pickup-dates',
    label: 'Abholdatum',
    labelPlural: 'Abholdaten',
    menuLabel: 'Abholdaten',
    icon: 'Event',
    parent: 'trash-settings',
    creatable: false,
    updatable: false,
    deletable: false,
    fields: [
      { name: 'id', type: FieldType.String, notEditable: true, hideInList: true },
      { name: 'date', label: 'Datum', type: FieldType.Date },
      { name: 'deviating', label: 'Abweichend', type: FieldType.Boolean },
    ],
  },

  // only viewable (tws.mobil api)
  {
    name: 'bus-stops',
    label: 'Bus Stop',
    labelPlural: 'Bus Stops',
    menuLabel: 'Bus Stops',
    creatable: false,
    updatable: false,
    deletable: false,
    noList: true,
    generalTabLabel: 'Allgemein',
    fields: [
      { name: 'id', type: FieldType.Number },
      { name: 'name', type: FieldType.String },
      { name: 'city', label: 'Stadt', type: FieldType.String },
      { name: 'placeType', label: 'Typ', type: FieldType.String },
      { name: 'latitude', label: 'Breitengrad', tab: 'Standort', type: FieldType.Number },
      { name: 'longitude', label: 'Längengrad', tab: 'Standort', type: FieldType.Number },
      {
        name: 'additional-map',
        label: 'Standort',
        type: FieldType.Map,
        tab: 'Standort',
        hideInList: true,
        map: { latitudeSource: 'latitude', longitudeSource: 'longitude', zoom: 12 },
      },
    ],
  },
  {
    name: 'bike-stations',
    label: 'Bike Station',
    labelPlural: 'Bike Stations',
    menuLabel: 'Bike Stations',
    creatable: false,
    updatable: false,
    deletable: false,
    noList: true,
    generalTabLabel: 'Allgemein',
    fields: [
      { name: 'id', type: FieldType.Number },
      { name: 'name', type: FieldType.String },
      { name: 'city', label: 'Stadt', type: FieldType.String },
      { name: 'placeType', label: 'Typ', type: FieldType.String },
      { name: 'latitude', label: 'Breitengrad', tab: 'Standort', type: FieldType.Number },
      { name: 'longitude', label: 'Längengrad', tab: 'Standort', type: FieldType.Number },
      {
        name: 'additional-map',
        label: 'Standort',
        type: FieldType.Map,
        tab: 'Standort',
        hideInList: true,
        map: { latitudeSource: 'latitude', longitudeSource: 'longitude', zoom: 12 },
      },
    ],
  },

  // Only needed to retrieve data
  {
    name: 'event-reminders',
    creatable: false,
    updatable: false,
    deletable: false,
    noShow: true,
    noList: true,
    fields: [],
  },
  {
    name: 'push-tokens',
    creatable: false,
    updatable: false,
    deletable: false,
    noShow: true,
    noList: true,
    fields: [],
  },
];

export default resources;
