import { ApolloClient, ApolloLink, HttpLink, InMemoryCache, split } from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import { WebSocketLink } from '@apollo/client/link/ws';
import { getMainDefinition } from '@apollo/client/utilities';
import { SubscriptionClient } from 'subscriptions-transport-ws';

const cache = new InMemoryCache();

const subscriptionClient: any = new SubscriptionClient(window.config.CITYHUB_ADMIN_API_WS_URL, {
  reconnect: true,
  connectionParams: () => {
    const token = localStorage.getItem('token');
    if (!token) return {};
    return { authToken: `Bearer ${token}` };
  },
});

const wsLink = new WebSocketLink(subscriptionClient);

const httpLink = new HttpLink({ uri: window.config.CITYHUB_ADMIN_API_GRAPHQL_URL, credentials: 'same-origin' });

const splitLink = split(
  ({ query }) => {
    const definition = getMainDefinition(query);
    return definition.kind === 'OperationDefinition' && definition.operation === 'subscription';
  },
  wsLink,
  httpLink,
);

const client = new ApolloClient({
  link: ApolloLink.from([
    setContext((_, { headers }) => {
      const token = localStorage.getItem('token');
      if (!token) return { ...headers };
      return { headers: { ...headers, Authorization: `Bearer ${token}` } };
    }),
    splitLink,
  ]),
  cache,
  defaultOptions: {
    watchQuery: { fetchPolicy: 'network-only' },
    query: { fetchPolicy: 'network-only' },
  },
});

export default client;
