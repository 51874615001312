import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import get from 'lodash.get';
import React from 'react';
import { FieldProps, FunctionField } from 'react-admin';
import { minutesToTime } from '../../utils/time';

const renderOpeningHours = (times: { from: number; to: number; entryUntil?: number }, index: number) => (
  <div key={index}>
    {minutesToTime(times.from)} - {minutesToTime(times.to)}
  </div>
);

const OpeningHoursField = ({ label, source, record, ...rest }: FieldProps) => (
  <FunctionField
    source={source}
    record={record}
    sortable={false}
    {...rest}
    render={(record: any) => {
      const days = get(record, source!);
      return (
        <TableContainer>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Monday</TableCell>
                <TableCell>Tuesday</TableCell>
                <TableCell>Wednesday</TableCell>
                <TableCell>Thursday</TableCell>
                <TableCell>Friday</TableCell>
                <TableCell>Saturday</TableCell>
                <TableCell>Sunday</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              <TableRow>
                <TableCell>{days.monday.map(renderOpeningHours)}</TableCell>
                <TableCell>{days.tuesday.map(renderOpeningHours)}</TableCell>
                <TableCell>{days.wednesday.map(renderOpeningHours)}</TableCell>
                <TableCell>{days.thursday.map(renderOpeningHours)}</TableCell>
                <TableCell>{days.friday.map(renderOpeningHours)}</TableCell>
                <TableCell>{days.saturday.map(renderOpeningHours)}</TableCell>
                <TableCell>{days.sunday.map(renderOpeningHours)}</TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
      );
    }}
  />
);

OpeningHoursField.defaultProps = {
  addLabel: true,
};

export default OpeningHoursField;
