import IconButton from '@material-ui/core/IconButton';
import InputAdornment from '@material-ui/core/InputAdornment';
import InsertInvitationIcon from '@material-ui/icons/InsertInvitation';
import React from 'react';
import {
  AutocompleteArrayInput,
  AutocompleteInput,
  BooleanInput,
  CheckboxGroupInput,
  FormDataConsumer,
  NullableBooleanInput,
  NumberInput,
  RadioButtonGroupInput,
  ReferenceArrayInput,
  ReferenceInput,
  SelectInput,
  TextInput,
} from 'react-admin';
import { DateInput, DateTimeInput } from 'react-admin-date-inputs';
import ImageInput from '../components/inputs/ImageInput';
import ImageListInput from '../components/inputs/ImageListInput';
import MapInput from '../components/inputs/MapInput';
import OpeningHoursInput from '../components/inputs/OpeningHoursInput';
import { FieldType, ResourceField } from '../utils/types';
import { renderField } from './field';

const findInputType = (field: ResourceField, isFilter: boolean, props: any) => {
  const type = field.editType || field.type;
  switch (type) {
    case FieldType.Boolean:
      return isFilter ? (
        <NullableBooleanInput
          {...props}
          key={field.name}
          source={field.name}
          label={field.label}
          alwaysOn
          style={{ minWidth: 200 }}
        />
      ) : (
        <BooleanInput
          {...props}
          key={field.name}
          source={field.name}
          label={field.label}
          defaultValue={!!field.defaultValue}
        />
      );
    case FieldType.Date:
      return (
        <DateInput
          {...props}
          key={field.name}
          source={field.name}
          label={field.label}
          defaultValue={field.defaultValue}
          alwaysOn={isFilter}
          options={{
            format: 'dd.MM.yyyy',
            ampm: false,
            InputProps: {
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton>
                    <InsertInvitationIcon />
                  </IconButton>
                </InputAdornment>
              ),
            },
          }}
        />
      );
    case FieldType.DateTime:
      return (
        <DateTimeInput
          {...props}
          key={field.name}
          source={field.name}
          label={field.label}
          defaultValue={field.defaultValue}
          alwaysOn={isFilter}
          options={{
            format: 'dd.MM.yyyy HH:mm',
            ampm: false,
            InputProps: {
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton>
                    <InsertInvitationIcon />
                  </IconButton>
                </InputAdornment>
              ),
            },
          }}
        />
      );
    case FieldType.Email:
      return (
        <TextInput
          {...props}
          key={field.name}
          type="email"
          source={field.name}
          label={field.label}
          defaultValue={field.defaultValue}
          alwaysOn={isFilter}
        />
      );
    case FieldType.Image:
      return (
        <ImageInput
          {...props}
          key={field.name}
          source={field.name}
          label={field.label}
          defaultValue={field.defaultValue}
          accept={["image/jpeg", "image/png"]}
          maxSize={10_000_000}
          {...field.image}
        />
      );
    case FieldType.ImageList:
      return (
        <ImageListInput
          {...props}
          key={field.name}
          source={field.name}
          label={field.label}
          defaultValue={field.defaultValue}
          accept="image/jpeg"
          maxSize={10_000_000}
          {...field.image}
        />
      );
    case FieldType.Map:
      if (!field.map) throw new Error(`Missing "map" property for field type "${field.type}"`);
      return (
        <MapInput
          {...props}
          key={field.name}
          source={field.name}
          label={field.label}
          latitudeSource={field.map.latitudeSource}
          longitudeSource={field.map.longitudeSource}
          zoom={field.map.zoom}
        />
      );
    case FieldType.MultiSelect:
      if (!field.choices) throw new Error(`Missing "choices" property for field type "${field.type}"`);
      return (
        <CheckboxGroupInput
          {...props}
          key={field.name}
          source={field.name}
          label={field.label}
          defaultValue={field.defaultValue}
          choices={field.choices}
          alwaysOn={isFilter}
        />
      );
    case FieldType.Number:
      return (
        <NumberInput
          {...props}
          key={field.name}
          source={field.name}
          label={field.label}
          defaultValue={field.defaultValue}
          alwaysOn={isFilter}
        />
      );
    case FieldType.OpeningHours:
      return <OpeningHoursInput {...props} key={field.name} source={field.name} label={field.label} />;
    case FieldType.ManyReference:
      if (!field.reference) throw new Error(`Missing "reference" prop for field type "${field.type}"`);
      return (
        <ReferenceArrayInput
          {...props}
          key={field.name}
          source={field.name}
          label={field.label}
          defaultValue={field.defaultValue}
          reference={field.reference.resource}
          parse={(list: string[]) => (isFilter ? list : list.map((id) => ({ id })))}
          format={(list: { id: string }[]) => (!list || isFilter ? list : list.map((i) => i.id))}
          filterToQuery={(search: string) =>
            search ? { [`${field.reference?.fieldName || 'name'}||$contL`]: search } : {}
          }
          alwaysOn={isFilter}
        >
          <AutocompleteArrayInput optionText={field.reference.fieldName || 'name'} fullWidth />
        </ReferenceArrayInput>
      );
    case FieldType.ManyReferenceList:
      console.warn(`${FieldType.ManyReferenceList} is field only`);
      return renderField(field, isFilter);
    case FieldType.RadioGroup:
      if (!field.choices) throw new Error(`Missing "choices" property for field type "${field.type}"`);
      return isFilter ? (
        <SelectInput
          {...props}
          key={field.name}
          source={field.name}
          label={field.label}
          defaultValue={field.defaultValue}
          choices={field.choices}
          alwaysOn
        />
      ) : (
        <RadioButtonGroupInput
          {...props}
          key={field.name}
          source={field.name}
          label={field.label}
          defaultValue={field.defaultValue}
          choices={field.choices}
          fullWidth
        />
      );
    case FieldType.Reference:
      if (!field.reference) throw new Error(`Missing "reference" property for field type "${field.type}"`);
      return (
        <ReferenceInput
          {...props}
          key={field.name}
          source={field.name}
          label={field.label}
          defaultValue={field.defaultValue}
          reference={field.reference.resource}
          filterToQuery={(search: string) =>
            search ? { [`${field.reference?.fieldName || 'name'}||$contL`]: search } : {}
          }
          alwaysOn={isFilter}
        >
          <AutocompleteInput optionText={field.reference.fieldName || 'name'} />
        </ReferenceInput>
      );
    case FieldType.String:
      return (
        <TextInput
          {...props}
          key={field.name}
          source={field.name}
          label={field.label}
          defaultValue={field.defaultValue}
          alwaysOn={isFilter}
          fullWidth={field.longText}
          multiline={field.longText}
        />
      );
    case FieldType.URL:
      return (
        <TextInput
          {...props}
          key={field.name}
          type="url"
          source={field.name}
          label={field.label}
          defaultValue={field.defaultValue}
          alwaysOn={isFilter}
        />
      );
    default:
      throw new Error(`Unknown field type "${field.type}"`);
  }
};

export const renderInput = (field: ResourceField, isFilter?: boolean) => {
  if (field.dependsOn)
    return (
      <FormDataConsumer>
        {({ formData, ...rest }) => field.dependsOn!(formData) && findInputType(field, !!isFilter, rest)}
      </FormDataConsumer>
    );

  return findInputType(field, !!isFilter, {});
};
