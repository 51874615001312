import { makeStyles } from '@material-ui/core/styles';
import get from 'lodash.get';
import React from 'react';
import { resolveImageUrl } from '../../utils/image';

const useStyles = makeStyles({
  list: {
    display: 'flex',
    listStyleType: 'none',
    flexWrap: 'wrap',
    margin: 0,
    padding: 0,
  },
  image: {
    margin: '0.5rem',
    maxHeight: '10rem',
  },
});

const ImageListField = (props: any) => {
  const { className, classes: classesOverride, emptyText, record, source, src, title, ...rest } = props;
  const sourceValue: any[] = get(record, source);
  const classes = useStyles(props);

  return (
    <ul className={classes.list} {...rest}>
      {sourceValue.map((file, index) => {
        return (
          <li key={index}>
            <img alt="" src={resolveImageUrl(file)} className={classes.image} />
          </li>
        );
      })}
    </ul>
  );
};

ImageListField.defaultProps = {
  addLabel: true,
};

export default ImageListField;
