import { makeStyles } from '@material-ui/core/styles';
import get from 'lodash/get';
import React, { FC } from 'react';
import { ImageFieldProps, sanitizeFieldRestProps } from 'react-admin';
import { resolveImageUrl } from '../../utils/image';

const useStyles = makeStyles(
  {
    image: {
      margin: '0.5rem',
      maxHeight: '10rem',
    },
  },
  { name: 'RaImageField' },
);

const ImageField: FC<ImageFieldProps> = (props) => {
  const { className, classes: classesOverride, emptyText, record, source, src, title, ...rest } = props;
  const classes = useStyles(props);
  const titleValue = get(record, title || '') || title;
  const sourceValue = get(record, source || '');

  return (
    <div className={className} {...sanitizeFieldRestProps(rest)}>
      <img title={titleValue} alt={titleValue} src={resolveImageUrl(sourceValue)} className={classes.image} />
    </div>
  );
};

ImageField.defaultProps = {
  addLabel: true,
};

export default ImageField;
