import Button from '@material-ui/core/Button';
import { makeStyles } from '@material-ui/core/styles';
import AddIcon from '@material-ui/icons/AddCircleOutline';
import RemoveIcon from '@material-ui/icons/RemoveCircleOutline';
import get from 'lodash.get';
import { InputProps, useInput, useTranslate } from 'ra-core';
import React, { useState } from 'react';
import TimespanInput from './TimespanInput';

const weekdays = ['monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday', 'sunday'];
const weekdayTranslations: any = {
  monday: 'Montag',
  tuesday: 'Dienstag',
  wednesday: 'Mittwoch',
  thursday: 'Donnerstag',
  friday: 'Freitag',
  saturday: 'Samstag',
  sunday: 'Sonntag',
};

const initialOpeningHours = Object.fromEntries(weekdays.map((day) => [day, []]));

const validateOpeningHours = (input: any) => {
  for (const [key, value] of Object.entries(input)) {
    if (weekdays.indexOf(key) === -1) return false;
    if (!Array.isArray(value)) return false;
  }

  return true;
};

const useStyles = makeStyles((theme) => ({
  timespanContainer: {
    marginBottom: 24,
  },
  timespanRow: {
    display: 'flex',
    marginTop: 24,
    marginBottom: 8,
  },
  timespansTitle: {
    color: theme.palette.grey[700],
  },
  action: {
    paddingTop: '0.5em',
  },
  leftIcon: {
    marginRight: theme.spacing(1),
  },
}));

const OpeningHoursInput = (props: InputProps) => {
  const {
    input: { onChange },
  } = useInput(props);

  const [openingHours, setOpeningHours] = useState(() => {
    try {
      const data = get(props.record, props.source);
      if (!validateOpeningHours(data)) throw new Error('invalid opening hours');
      return data;
    } catch (error) {
      return initialOpeningHours;
    }
  });
  const classes = useStyles();
  const translate = useTranslate();

  const addTimespan = (day: string) => {
    setOpeningHours((existing: any) => {
      const nextValue = { ...existing, [day]: [...existing[day], { from: 540, to: 1080 }] };
      onChange({ target: { value: nextValue } });
      return nextValue;
    });
  };

  const removeTimespan = (day: string, index: number) => {
    setOpeningHours((existing: any) => {
      const nextValue = { ...existing, [day]: existing[day].filter((_: any, i: number) => i !== index) };
      onChange({ target: { value: nextValue } });
      return nextValue;
    });
  };

  const updateTimespan = (day: string, index: number, value: any) => {
    setOpeningHours((existing: any) => {
      const nextValue = {
        ...existing,
        [day]: existing[day].map((hours: any, i: number) => (i === index ? value : hours)),
      };
      onChange({ target: { value: nextValue } });
      return nextValue;
    });
  };

  return (
    <div>
      <h4>Öffnungszeiten</h4>

      {weekdays.map((day) => {
        const hours = openingHours[day];
        return (
          <div key={day} className={classes.timespanContainer}>
            <p className={classes.timespansTitle}>{weekdayTranslations[day]}</p>
            {hours.map((hour: any, index: number) => (
              <div key={index} className={classes.timespanRow}>
                <TimespanInput
                  from={hour.from}
                  to={hour.to}
                  onChange={(value: any) => updateTimespan(day, index, value)}
                />

                <span className={classes.action}>
                  <Button size="small" onClick={() => removeTimespan(day, index)}>
                    <RemoveIcon className={classes.leftIcon} />
                    {translate('ra.action.remove')}
                  </Button>
                </span>
              </div>
            ))}

            <span className={classes.action}>
              <Button size="small" onClick={() => addTimespan(day)}>
                <AddIcon className={classes.leftIcon} />
                {'Zeitraum hinzufügen'}
              </Button>
            </span>
          </div>
        );
      })}
    </div>
  );
};

export default OpeningHoursInput;
