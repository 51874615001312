import * as MenuIcons from '@material-ui/icons';
import React from 'react';
import { MenuIcon, ResourceDefinition } from '../utils/types';

export const createMenuIcon = (iconName: MenuIcon) => MenuIcons[iconName];

export const Title = ({ record, def }: { record?: any; def: ResourceDefinition }) => (
  <>
    {def.label || def.name} - {record ? record.name || record.title : ''}
  </>
);
