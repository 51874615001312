import Chip, { ChipProps } from '@material-ui/core/Chip';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import classnames from 'classnames';
import get from 'lodash/get';
import { fieldPropTypes, InjectedFieldProps, PublicFieldProps } from 'ra-ui-materialui/lib/field/types';
import React, { FC, memo } from 'react';
import { sanitizeFieldRestProps } from 'react-admin';

const useStyles = makeStyles({ chip: { margin: 4, cursor: 'inherit' } }, { name: 'RaChipField' });

export const ChipField: FC<ChipFieldProps> = memo<ChipFieldProps>((props) => {
  const { className, classes: classesOverride, source, record = {}, emptyText, replacements, ...rest } = props;
  const classes = useStyles(props);
  const value = source ? get(record, source) : record;

  if (value == null && emptyText) {
    return (
      <Typography component="span" variant="body2" className={className} {...sanitizeFieldRestProps(rest)}>
        {emptyText}
      </Typography>
    );
  }

  const replaced = replacements && replacements[value] ? replacements[value] : value;

  return <Chip className={classnames(classes.chip, className)} label={replaced} {...sanitizeFieldRestProps(rest)} />;
});

ChipField.defaultProps = {
  addLabel: true,
};

ChipField.propTypes = {
  ...ChipField.propTypes,
  ...fieldPropTypes,
};

export interface ChipFieldProps extends PublicFieldProps, InjectedFieldProps, Omit<ChipProps, 'label'> {
  replacements?: { [key: string]: string };
}

export default ChipField;
